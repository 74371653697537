import React from 'react'
import { Spinner } from 'react-bootstrap'

const Loader = ({ lazy }) => {
  return lazy ? (
    <div style={{ paddingTop: '150px' }}>
      <Spinner
        animation='border'
        role='status'
        style={{
          width: '50px',
          height: '50px',
          margin: 'auto',
          display: 'block',
        }}
      ></Spinner>
    </div>
  ) : (
    <Spinner
      animation='border'
      role='status'
      style={{
        width: '50px',
        height: '50px',
        margin: 'auto',
        display: 'block',
      }}
    ></Spinner>
  )
}

export default Loader
