export const CATEGORY_CREATE_REQUEST = 'CATEGORY_CREATE_REQUEST'
export const CATEGORY_CREATE_SUCCESS = 'CATEGORY_CREATE_SUCCESS'
export const CATEGORY_CREATE_FAIL = 'CATEGORY_CREATE_FAIL'
export const CATEGORY_CREATE_RESET = 'CATEGORY_CREATE_RESET'

export const CATEGORY_LIST_REQUEST = 'CATEGORY_LIST_REQUEST'
export const CATEGORY_LIST_SUCCESS = 'CATEGORY_LIST_SUCCESS'
export const CATEGORY_LIST_FAIL = 'CATEGORY_LIST_FAIL'
export const CATEGORY_LIST_RESET = 'CATEGORY_LIST_RESET'

export const CATEGORY_DETAILS_REQUEST = 'CATEGORY_DETAILS_REQUEST'
export const CATEGORY_DETAILS_SUCCESS = 'CATEGORY_DETAILS_SUCCESS'
export const CATEGORY_DETAILS_FAIL = 'CATEGORY_DETAILS_FAIL'
export const CATEGORY_DETAILS_RESET = 'CATEGORY_DETAILS_RESET'

export const CATEGORY_UPDATE_REQUEST = 'CATEGORY_UPDATE_REQUEST'
export const CATEGORY_UPDATE_SUCCESS = 'CATEGORY_UPDATE_SUCCESS'
export const CATEGORY_UPDATE_FAIL = 'CATEGORY_UPDATE_FAIL'
export const CATEGORY_UPDATE_RESET = 'CATEGORY_UPDATE_RESET'

export const CATEGORY_DELETE_REQUEST = 'CATEGORY_DELETE_REQUEST'
export const CATEGORY_DELETE_SUCCESS = 'CATEGORY_DELETE_SUCCESS'
export const CATEGORY_DELETE_FAIL = 'CATEGORY_DELETE_FAIL'
export const CATEGORY_DELETE_RESET = 'CATEGORY_DELETE_RESET'

export const SUB_CATEGORY_CREATE_REQUEST = 'SUB_CATEGORY_CREATE_REQUEST'
export const SUB_CATEGORY_CREATE_SUCCESS = 'SUB_CATEGORY_CREATE_SUCCESS'
export const SUB_CATEGORY_CREATE_FAIL = 'SUB_CATEGORY_CREATE_FAIL'
export const SUB_CATEGORY_CREATE_RESET = 'SUB_CATEGORY_CREATE_RESET'

export const SUB_CATEGORY_LIST_REQUEST = 'SUB_CATEGORY_LIST_REQUEST'
export const SUB_CATEGORY_LIST_SUCCESS = 'SUB_CATEGORY_LIST_SUCCESS'
export const SUB_CATEGORY_LIST_FAIL = 'SUB_CATEGORY_LIST_FAIL'
export const SUB_CATEGORY_LIST_RESET = 'SUB_CATEGORY_LIST_RESET'

export const TOPIC_CREATE_REQUEST = 'TOPIC_CREATE_REQUEST'
export const TOPIC_CREATE_SUCCESS = 'TOPIC_CREATE_SUCCESS'
export const TOPIC_CREATE_FAIL = 'TOPIC_CREATE_FAIL'
export const TOPIC_CREATE_RESET = 'TOPIC_CREATE_RESET'

export const TOPIC_LIST_REQUEST = 'TOPIC_LIST_REQUEST'
export const TOPIC_LIST_SUCCESS = 'TOPIC_LIST_SUCCESS'
export const TOPIC_LIST_FAIL = 'TOPIC_LIST_FAIL'
export const TOPIC_LIST_RESET = 'TOPIC_LIST_RESET'

export const SUBJECT_CREATE_REQUEST = 'SUBJECT_CREATE_REQUEST'
export const SUBJECT_CREATE_SUCCESS = 'SUBJECT_CREATE_SUCCESS'
export const SUBJECT_CREATE_FAIL = 'SUBJECT_CREATE_FAIL'
export const SUBJECT_CREATE_RESET = 'SUBJECT_CREATE_RESET'

export const SUBJECT_LIST_REQUEST = 'SUBJECT_LIST_REQUEST'
export const SUBJECT_LIST_SUCCESS = 'SUBJECT_LIST_SUCCESS'
export const SUBJECT_LIST_FAIL = 'SUBJECT_LIST_FAIL'
export const SUBJECT_LIST_RESET = 'SUBJECT_LIST_RESET'
