import {
  BATCH_DETAILS_REQUEST,
  BATCH_DETAILS_SUCCESS,
  BATCH_DETAILS_FAIL,
  BATCH_LIST_REQUEST,
  BATCH_LIST_SUCCESS,
  BATCH_LIST_FAIL,
  BATCH_DELETE_REQUEST,
  BATCH_DELETE_FAIL,
  BATCH_DELETE_SUCCESS,
  BATCH_CREATE_REQUEST,
  BATCH_CREATE_SUCCESS,
  BATCH_CREATE_FAIL,
  BATCH_CREATE_RESET,
  BATCH_UPDATE_FAIL,
  BATCH_UPDATE_SUCCESS,
  BATCH_UPDATE_REQUEST,
  BATCH_UPDATE_RESET,
  BATCH_CREATE_REVIEW_REQUEST,
  BATCH_CREATE_REVIEW_SUCCESS,
  BATCH_CREATE_REVIEW_FAIL,
  BATCH_CREATE_REVIEW_RESET,
  BATCH_DEMO_OVER_REQUEST,
  BATCH_DEMO_OVER_SUCCESS,
  BATCH_DEMO_OVER_FAIL,
  BATCH_DEMO_REQUEST,
  BATCH_DEMO_SUCCESS,
  BATCH_DEMO_FAIL,
  BATCH_DEMO_RESET,
  BATCH_PAY_SUCCESS,
  BATCH_PAY_FAIL,
  BATCH_PAY_REQUEST,
  BATCH_PAY_RESET,
  BATCH_TOP_RATED_REQUEST,
  BATCH_TOP_RATED_SUCCESS,
  BATCH_TOP_RATED_FAIL,
  BATCH_QUESTION_REQUEST,
  BATCH_QUESTION_SUCCESS,
  BATCH_QUESTION_FAIL,
  BATCH_QUESTION_RESET,
  BATCH_ANSWER_REQUEST,
  BATCH_ANSWER_SUCCESS,
  BATCH_ANSWER_FAIL,
  BATCH_ANSWER_RESET,
  BATCH_ASSIGNMENT_CREATE_REQUEST,
  BATCH_ASSIGNMENT_CREATE_SUCCESS,
  BATCH_ASSIGNMENT_CREATE_FAIL,
  BATCH_ASSIGNMENT_CREATE_RESET,
  BATCH_ASSIGNMENT_LIST_REQUEST,
  BATCH_ASSIGNMENT_LIST_SUCCESS,
  BATCH_ASSIGNMENT_LIST_FAIL,
  BATCH_ASSIGNMENT_DELETE_REQUEST,
  BATCH_ASSIGNMENT_DELETE_SUCCESS,
  BATCH_ASSIGNMENT_DELETE_FAIL,
  BATCH_ASSIGNMENT_UPDATE_REQUEST,
  BATCH_ASSIGNMENT_UPDATE_SUCCESS,
  BATCH_ASSIGNMENT_UPDATE_FAIL,
  BATCH_ASSIGNMENT_UPDATE_RESET,
  BATCH_ASSIGNMENT_DETAILS_REQUEST,
  BATCH_ASSIGNMENT_DETAILS_SUCCESS,
  BATCH_ASSIGNMENT_DETAILS_FAIL,
  BATCH_ASSIGNMENT_SOLVE_REQUEST,
  BATCH_ASSIGNMENT_SOLVE_SUCCESS,
  BATCH_ASSIGNMENT_SOLVE_FAIL,
  BATCH_ASSIGNMENT_SOLVE_RESET,
} from '../constants/batchConstants'

export const batchListReducer = (state = { batches: [] }, action) => {
  switch (action.type) {
    case BATCH_LIST_REQUEST:
      return { loading: true, batches: [] }
    case BATCH_LIST_SUCCESS:
      return {
        loading: false,
        batches: action.payload.batches,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case BATCH_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const batchTopRatedReducer = (state = { batches: [] }, action) => {
  switch (action.type) {
    case BATCH_TOP_RATED_REQUEST:
      return { loading: true, batches: [] }
    case BATCH_TOP_RATED_SUCCESS:
      return {
        loading: false,
        batches: action.payload,
      }
    case BATCH_TOP_RATED_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const batchDetailsReducer = (
  state = { batch: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case BATCH_DETAILS_REQUEST:
      return { loading: true, ...state }
    case BATCH_DETAILS_SUCCESS:
      return { loading: false, batch: action.payload }
    case BATCH_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const batchReviewCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case BATCH_CREATE_REVIEW_REQUEST:
      return { loading: true }
    case BATCH_CREATE_REVIEW_SUCCESS:
      return { loading: false, success: true }
    case BATCH_CREATE_REVIEW_FAIL:
      return { loading: false, error: action.payload }
    case BATCH_CREATE_REVIEW_RESET:
      return {}
    default:
      return state
  }
}

export const batchDemoReducer = (state = {}, action) => {
  switch (action.type) {
    case BATCH_DEMO_REQUEST:
      return { loading: true }
    case BATCH_DEMO_SUCCESS:
      return { loading: false, success: true }
    case BATCH_DEMO_FAIL:
      return { loading: false, error: action.payload }
    case BATCH_DEMO_RESET:
      return {}
    default:
      return state
  }
}

export const batchDemoOverReducer = (state = {}, action) => {
  switch (action.type) {
    case BATCH_DEMO_OVER_REQUEST:
      return { loading: true }
    case BATCH_DEMO_OVER_SUCCESS:
      return { loading: false, batches: action.payload }
    case BATCH_DEMO_OVER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const batchDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case BATCH_DELETE_REQUEST:
      return { loading: true, ...state }
    case BATCH_DELETE_SUCCESS:
      return { loading: false, success: true }
    case BATCH_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const batchPayReducer = (state = {}, action) => {
  switch (action.type) {
    case BATCH_PAY_REQUEST:
      return { loading: true, ...state }
    case BATCH_PAY_SUCCESS:
      return { loading: false, success: true, batch: action.payload }
    case BATCH_PAY_FAIL:
      return { loading: false, error: action.payload }
    case BATCH_PAY_RESET:
      return {}
    default:
      return state
  }
}

export const batchCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case BATCH_CREATE_REQUEST:
      return { loading: true, ...state }
    case BATCH_CREATE_SUCCESS:
      return { loading: false, success: true, batch: action.payload }
    case BATCH_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case BATCH_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const batchUpdateReducer = (state = { batch: {} }, action) => {
  switch (action.type) {
    case BATCH_UPDATE_REQUEST:
      return { loading: true, ...state }
    case BATCH_UPDATE_SUCCESS:
      return { loading: false, success: true, batch: action.payload }
    case BATCH_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case BATCH_UPDATE_RESET:
      return { batch: {} }
    default:
      return state
  }
}

export const batchCreateQuestionReducer = (state = {}, action) => {
  switch (action.type) {
    case BATCH_QUESTION_REQUEST:
      return { loading: true }
    case BATCH_QUESTION_SUCCESS:
      return { loading: false, success: true }
    case BATCH_QUESTION_FAIL:
      return { loading: false, error: action.payload }
    case BATCH_QUESTION_RESET:
      return {}
    default:
      return state
  }
}

export const batchCreateAnswerReducer = (state = {}, action) => {
  switch (action.type) {
    case BATCH_ANSWER_REQUEST:
      return { loading: true }
    case BATCH_ANSWER_SUCCESS:
      return { loading: false, success: true }
    case BATCH_ANSWER_FAIL:
      return { loading: false, error: action.payload }
    case BATCH_ANSWER_RESET:
      return {}
    default:
      return state
  }
}

export const batchCreateAssignmentReducer = (state = {}, action) => {
  switch (action.type) {
    case BATCH_ASSIGNMENT_CREATE_REQUEST:
      return { loading: true }
    case BATCH_ASSIGNMENT_CREATE_SUCCESS:
      return { loading: false, success: true }
    case BATCH_ASSIGNMENT_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case BATCH_ASSIGNMENT_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const batchListAssignmentReducer = (state = {}, action) => {
  switch (action.type) {
    case BATCH_ASSIGNMENT_LIST_REQUEST:
      return { loading: true }
    case BATCH_ASSIGNMENT_LIST_SUCCESS:
      return { loading: false, success: true, assignments: action.payload }
    case BATCH_ASSIGNMENT_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const batchDeleteAssignmentReducer = (state = {}, action) => {
  switch (action.type) {
    case BATCH_ASSIGNMENT_DELETE_REQUEST:
      return { loading: true }
    case BATCH_ASSIGNMENT_DELETE_SUCCESS:
      return { loading: false, success: true }
    case BATCH_ASSIGNMENT_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const batchUpdateAssignmentReducer = (state = {}, action) => {
  switch (action.type) {
    case BATCH_ASSIGNMENT_UPDATE_REQUEST:
      return { loading: true, ...state }
    case BATCH_ASSIGNMENT_UPDATE_SUCCESS:
      return { loading: false, success: true, assignment: action.payload }
    case BATCH_ASSIGNMENT_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case BATCH_ASSIGNMENT_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const batchAssignmentDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case BATCH_ASSIGNMENT_DETAILS_REQUEST:
      return { loading: true }
    case BATCH_ASSIGNMENT_DETAILS_SUCCESS:
      return { loading: false, success: true, assignment: action.payload }
    case BATCH_ASSIGNMENT_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const batchSolveAssignmentReducer = (state = {}, action) => {
  switch (action.type) {
    case BATCH_ASSIGNMENT_SOLVE_REQUEST:
      return { loading: true }
    case BATCH_ASSIGNMENT_SOLVE_SUCCESS:
      return { loading: false, success: true, result: action.payload }
    case BATCH_ASSIGNMENT_SOLVE_FAIL:
      return { loading: false, error: action.payload }
    case BATCH_ASSIGNMENT_SOLVE_RESET:
      return {}
    default:
      return state
  }
}
