export const COURSE_LIST_REQUEST = 'COURSE_LIST_REQUEST'
export const COURSE_LIST_SUCCESS = 'COURSE_LIST_SUCCESS'
export const COURSE_LIST_FAIL = 'COURSE_LIST_FAIL'

export const COURSE_DETAILS_REQUEST = 'COURSE_DETAILS_REQUEST'
export const COURSE_DETAILS_SUCCESS = 'COURSE_DETAILS_SUCCESS'
export const COURSE_DETAILS_FAIL = 'COURSE_DETAILS_FAIL'

export const COURSE_DELETE_REQUEST = 'COURSE_DELETE_REQUEST'
export const COURSE_DELETE_SUCCESS = 'COURSE_DELETE_SUCCESS'
export const COURSE_DELETE_FAIL = 'COURSE_DELETE_FAIL'

export const COURSE_CREATE_REQUEST = 'COURSE_CREATE_REQUEST'
export const COURSE_CREATE_SUCCESS = 'COURSE_CREATE_SUCCESS'
export const COURSE_CREATE_FAIL = 'COURSE_CREATE_FAIL'
export const COURSE_CREATE_RESET = 'COURSE_CREATE_RESET'

export const COURSE_UPDATE_REQUEST = 'COURSE_UPDATE_REQUEST'
export const COURSE_UPDATE_SUCCESS = 'COURSE_UPDATE_SUCCESS'
export const COURSE_UPDATE_FAIL = 'COURSE_UPDATE_FAIL'
export const COURSE_UPDATE_RESET = 'COURSE_UPDATE_RESET'

export const COURSE_CREATE_REVIEW_REQUEST = 'COURSE_CREATE_REVIEW_REQUEST'
export const COURSE_CREATE_REVIEW_SUCCESS = 'COURSE_CREATE_REVIEW_SUCCESS'
export const COURSE_CREATE_REVIEW_FAIL = 'COURSE_CREATE_REVIEW_FAIL'
export const COURSE_CREATE_REVIEW_RESET = 'COURSE_CREATE_REVIEW_RESET'

export const COURSE_PAY_REQUEST = 'COURSE_PAY_REQUEST'
export const COURSE_PAY_SUCCESS = 'COURSE_PAY_SUCCESS'
export const COURSE_PAY_FAIL = 'COURSE_PAY_FAIL'
export const COURSE_PAY_RESET = 'COURSE_PAY_RESET'

export const COURSE_TOP_RATED_REQUEST = 'COURSE_TOP_RATED_REQUEST'
export const COURSE_TOP_RATED_SUCCESS = 'COURSE_TOP_RATED_SUCCESS'
export const COURSE_TOP_RATED_FAIL = 'COURSE_TOP_RATED_FAIL'

export const COURSE_QUESTION_REQUEST = 'COURSE_QUESTION_REQUEST'
export const COURSE_QUESTION_SUCCESS = 'COURSE_QUESTION_SUCCESS'
export const COURSE_QUESTION_FAIL = 'COURSE_QUESTION_FAIL'
export const COURSE_QUESTION_RESET = 'COURSE_QUESTION_RESET'

export const COURSE_ANSWER_REQUEST = 'COURSE_ANSWER_REQUEST'
export const COURSE_ANSWER_SUCCESS = 'COURSE_ANSWER_SUCCESS'
export const COURSE_ANSWER_FAIL = 'COURSE_ANSWER_FAIL'
export const COURSE_ANSWER_RESET = 'COURSE_ANSWER_RESET'

export const COURSE_ASSIGNMENT_CREATE_REQUEST =
  'COURSE_ASSIGNMENT_CREATE_REQUEST'
export const COURSE_ASSIGNMENT_CREATE_SUCCESS =
  'COURSE_ASSIGNMENT_CREATE_SUCCESS'
export const COURSE_ASSIGNMENT_CREATE_FAIL = 'COURSE_ASSIGNMENT_CREATE_FAIL'
export const COURSE_ASSIGNMENT_CREATE_RESET = 'COURSE_ASSIGNMENT_CREATE_RESET'

export const COURSE_ASSIGNMENT_LIST_REQUEST = 'COURSE_ASSIGNMENT_LIST_REQUEST'
export const COURSE_ASSIGNMENT_LIST_SUCCESS = 'COURSE_ASSIGNMENT_LIST_SUCCESS'
export const COURSE_ASSIGNMENT_LIST_FAIL = 'COURSE_ASSIGNMENT_LIST_FAIL'

export const COURSE_ASSIGNMENT_DELETE_REQUEST =
  'COURSE_ASSIGNMENT_DELETE_REQUEST'
export const COURSE_ASSIGNMENT_DELETE_SUCCESS =
  'COURSE_ASSIGNMENT_DELETE_SUCCESS'
export const COURSE_ASSIGNMENT_DELETE_FAIL = 'COURSE_ASSIGNMENT_DELETE_FAIL'

export const COURSE_ASSIGNMENT_UPDATE_REQUEST =
  'COURSE_ASSIGNMENT_UPDATE_REQUEST'
export const COURSE_ASSIGNMENT_UPDATE_SUCCESS =
  'COURSE_ASSIGNMENT_UPDATE_SUCCESS'
export const COURSE_ASSIGNMENT_UPDATE_FAIL = 'COURSE_ASSIGNMENT_UPDATE_FAIL'
export const COURSE_ASSIGNMENT_UPDATE_RESET = 'COURSE_ASSIGNMENT_UPDATE_RESET'

export const COURSE_ASSIGNMENT_DETAILS_REQUEST =
  'COURSE_ASSIGNMENT_DETAILS_REQUEST'
export const COURSE_ASSIGNMENT_DETAILS_SUCCESS =
  'COURSE_ASSIGNMENT_DETAILS_SUCCESS'
export const COURSE_ASSIGNMENT_DETAILS_FAIL = 'COURSE_ASSIGNMENT_DETAILS_FAIL'
export const COURSE_ASSIGNMENT_DETAILS_RESET = 'COURSE_ASSIGNMENT_DETAILS_RESET'

export const COURSE_ASSIGNMENT_SOLVE_REQUEST = 'COURSE_ASSIGNMENT_SOLVE_REQUEST'
export const COURSE_ASSIGNMENT_SOLVE_SUCCESS = 'COURSE_ASSIGNMENT_SOLVE_SUCCESS'
export const COURSE_ASSIGNMENT_SOLVE_FAIL = 'COURSE_ASSIGNMENT_SOLVE_FAIL'
export const COURSE_ASSIGNMENT_SOLVE_RESET = 'COURSE_ASSIGNMENT_SOLVE_RESET'

export const COURSE_ASSIGNMENT_ADD_QUESTIONS_REQUEST =
  'COURSE_ASSIGNMENT_ADD_QUESTIONS_REQUEST'
export const COURSE_ASSIGNMENT_ADD_QUESTIONS_SUCCESS =
  'COURSE_ASSIGNMENT_ADD_QUESTIONS_SUCCESS'
export const COURSE_ASSIGNMENT_ADD_QUESTIONS_FAIL =
  'COURSE_ASSIGNMENT_ADD_QUESTIONS_FAIL'
export const COURSE_ASSIGNMENT_ADD_QUESTIONS_RESET =
  'COURSE_ASSIGNMENT_ADD_QUESTIONS_RESET'

export const COURSE_ASSIGNMENT_QUESTIONS_LIST_REQUEST =
  'COURSE_ASSIGNMENT_QUESTIONS_LIST_REQUEST'
export const COURSE_ASSIGNMENT_QUESTIONS_LIST_SUCCESS =
  'COURSE_ASSIGNMENT_QUESTIONS_LIST_SUCCESS'
export const COURSE_ASSIGNMENT_QUESTIONS_LIST_FAIL =
  'COURSE_ASSIGNMENT_QUESTIONS_LIST_FAIL'

export const COURSE_INSTRUCTOR_UPDATE_REQUEST =
  'COURSE_INSTRUCTOR_UPDATE_REQUEST'
export const COURSE_INSTRUCTOR_UPDATE_SUCCESS =
  'COURSE_INSTRUCTOR_UPDATE_SUCCESS'
export const COURSE_INSTRUCTOR_UPDATE_FAIL = 'COURSE_INSTRUCTOR_UPDATE_FAIL'
export const COURSE_INSTRUCTOR_UPDATE_RESET = 'COURSE_INSTRUCTOR_UPDATE_RESET'
