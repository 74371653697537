import {
  CATEGORY_CREATE_REQUEST,
  CATEGORY_CREATE_SUCCESS,
  CATEGORY_CREATE_FAIL,
  CATEGORY_CREATE_RESET,
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_FAIL,
  CATEGORY_LIST_RESET,
  CATEGORY_DETAILS_REQUEST,
  CATEGORY_DETAILS_SUCCESS,
  CATEGORY_DETAILS_FAIL,
  CATEGORY_DETAILS_RESET,
  CATEGORY_UPDATE_REQUEST,
  CATEGORY_UPDATE_SUCCESS,
  CATEGORY_UPDATE_FAIL,
  CATEGORY_UPDATE_RESET,
  CATEGORY_DELETE_REQUEST,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_DELETE_FAIL,
  CATEGORY_DELETE_RESET,
  SUB_CATEGORY_CREATE_REQUEST,
  SUB_CATEGORY_CREATE_SUCCESS,
  SUB_CATEGORY_CREATE_FAIL,
  SUB_CATEGORY_CREATE_RESET,
  SUB_CATEGORY_LIST_REQUEST,
  SUB_CATEGORY_LIST_SUCCESS,
  SUB_CATEGORY_LIST_FAIL,
  SUB_CATEGORY_LIST_RESET,
  TOPIC_CREATE_REQUEST,
  TOPIC_CREATE_SUCCESS,
  TOPIC_CREATE_FAIL,
  TOPIC_CREATE_RESET,
  TOPIC_LIST_REQUEST,
  TOPIC_LIST_SUCCESS,
  TOPIC_LIST_FAIL,
  TOPIC_LIST_RESET,
  SUBJECT_CREATE_REQUEST,
  SUBJECT_CREATE_SUCCESS,
  SUBJECT_CREATE_FAIL,
  SUBJECT_CREATE_RESET,
  SUBJECT_LIST_REQUEST,
  SUBJECT_LIST_SUCCESS,
  SUBJECT_LIST_FAIL,
  SUBJECT_LIST_RESET,
} from '../constants/categoryConstants'

export const categoryCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_CREATE_REQUEST:
      return { loading: true }
    case CATEGORY_CREATE_SUCCESS:
      return { loading: false, success: true, status: action.payload }
    case CATEGORY_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case CATEGORY_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const listCategoriesReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_LIST_REQUEST:
      return { loading: true }
    case CATEGORY_LIST_SUCCESS:
      return { loading: false, success: true, categories: action.payload }
    case CATEGORY_LIST_FAIL:
      return { loading: false, error: action.payload }
    case CATEGORY_LIST_RESET:
      return {}
    default:
      return state
  }
}

export const categoryDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_DETAILS_REQUEST:
      return { loading: true }
    case CATEGORY_DETAILS_SUCCESS:
      return { loading: false, success: true, category: action.payload }
    case CATEGORY_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    case CATEGORY_DETAILS_RESET:
      return {}
    default:
      return state
  }
}

export const categoryUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_UPDATE_REQUEST:
      return { loading: true }
    case CATEGORY_UPDATE_SUCCESS:
      return { loading: false, success: true, status: action.payload }
    case CATEGORY_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case CATEGORY_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const categoryDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_DELETE_REQUEST:
      return { loading: true }
    case CATEGORY_DELETE_SUCCESS:
      return { loading: false, success: true, status: action.payload }
    case CATEGORY_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case CATEGORY_DELETE_RESET:
      return {}
    default:
      return state
  }
}

export const subCategoryCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SUB_CATEGORY_CREATE_REQUEST:
      return { loading: true }
    case SUB_CATEGORY_CREATE_SUCCESS:
      return { loading: false, success: true, status: action.payload }
    case SUB_CATEGORY_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case SUB_CATEGORY_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const listSubCategoriesReducer = (state = {}, action) => {
  switch (action.type) {
    case SUB_CATEGORY_LIST_REQUEST:
      return { loading: true }
    case SUB_CATEGORY_LIST_SUCCESS:
      return { loading: false, success: true, subCategories: action.payload }
    case SUB_CATEGORY_LIST_FAIL:
      return { loading: false, error: action.payload }
    case SUB_CATEGORY_LIST_RESET:
      return {}
    default:
      return state
  }
}

export const topicCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TOPIC_CREATE_REQUEST:
      return { loading: true }
    case TOPIC_CREATE_SUCCESS:
      return { loading: false, success: true, status: action.payload }
    case TOPIC_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case TOPIC_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const listTopicsReducer = (state = {}, action) => {
  switch (action.type) {
    case TOPIC_LIST_REQUEST:
      return { loading: true }
    case TOPIC_LIST_SUCCESS:
      return { loading: false, success: true, topics: action.payload }
    case TOPIC_LIST_FAIL:
      return { loading: false, error: action.payload }
    case TOPIC_LIST_RESET:
      return {}
    default:
      return state
  }
}

export const subjectCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBJECT_CREATE_REQUEST:
      return { loading: true }
    case SUBJECT_CREATE_SUCCESS:
      return { loading: false, success: true, status: action.payload }
    case SUBJECT_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case SUBJECT_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const listSubjectsReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBJECT_LIST_REQUEST:
      return { loading: true }
    case SUBJECT_LIST_SUCCESS:
      return { loading: false, success: true, subject: action.payload }
    case SUBJECT_LIST_FAIL:
      return { loading: false, error: action.payload }
    case SUBJECT_LIST_RESET:
      return {}
    default:
      return state
  }
}
