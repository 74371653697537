import axios from 'axios'
import { CART_CLEAR } from '../constants/cartConstants'

import {
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_BY_ADMIN_FAIL,
  USER_DETAILS_BY_ADMIN_REQUEST,
  USER_DETAILS_BY_ADMIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_DETAILS_RESET,
  USER_LIST_FAIL,
  USER_LIST_SUCCESS,
  USER_LIST_REQUEST,
  USER_LIST_RESET,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_FAIL,
  USER_UPDATE_SUCCESS,
  USER_VERIFY_RESEND_FAIL,
  USER_VERIFY_RESEND_REQUEST,
  USER_VERIFY_RESEND_SUCCESS,
  USER_FORGOT_PASS_FAIL,
  USER_FORGOT_PASS_REQUEST,
  USER_FORGOT_PASS_SUCCESS,
  USER_RESET_PASS_FAIL,
  USER_RESET_PASS_REQUEST,
  USER_RESET_PASS_SUCCESS,
  USER_I_LIST_FAIL,
  USER_I_LIST_REQUEST,
  USER_I_LIST_SUCCESS,
  USER_UPDATE_SACHIV_FAIL,
  USER_UPDATE_SACHIV_REQUEST,
  USER_UPDATE_SACHIV_SUCCESS,
  USER_REGISTER_RESET,
} from '../constants/userConstants'

export const login = (username, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const emailRegexp =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

    if (emailRegexp.test(username)) {
      let email = username

      const { data } = await axios.post(
        '/api/users/login',
        { email, password },
        config
      )

      localStorage.setItem('userInfo', JSON.stringify(data))

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      })
    } else {
      const { data } = await axios.post(
        '/api/users/login',
        { username, password },
        config
      )

      localStorage.setItem('userInfo', JSON.stringify(data))

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      })
    }
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const logout = () => async (dispatch) => {
  localStorage.removeItem('userInfo')

  dispatch({ type: USER_LOGOUT })
  dispatch({ type: USER_DETAILS_RESET })
  dispatch({ type: USER_LIST_RESET })
  dispatch({ type: USER_REGISTER_RESET })
  dispatch({ type: CART_CLEAR })
  await axios.get('/api/users/logout')
}

export const register =
  (name, email, password, phoneNumber) => async (dispatch) => {
    try {
      dispatch({
        type: USER_REGISTER_REQUEST,
      })

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      const { data } = await axios.post(
        '/api/users',
        { name, email, password, phoneNumber },
        config
      )

      const res = await axios.post(`/api/upload/new/${data._id}`)

      await axios.put('/api/users/profile', { image: res.data }, config)

      const userData = { ...data, loggedInAt: new Date().toISOString() }

      localStorage.setItem('userInfo', JSON.stringify(userData))

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      })

      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getUserDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const { data } = await axios.get(`/api/users/${id}`, config)

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getUserByAdmin = (id) => async (dispatch) => {
  try {
    dispatch({
      type: USER_DETAILS_BY_ADMIN_REQUEST,
    })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const { data } = await axios.get(`/api/users/${id}`, config)

    dispatch({
      type: USER_DETAILS_BY_ADMIN_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_DETAILS_BY_ADMIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateUserProfile = (user) => async (dispatch) => {
  try {
    dispatch({
      type: USER_UPDATE_PROFILE_REQUEST,
    })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const { data } = await axios.put(`/api/users/profile`, user, config)

    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listUsers = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    })

    const { data } = await axios.get(`/api/users/`)

    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listIUsers = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_I_LIST_REQUEST,
    })

    const { data } = await axios.get(`/api/users/instructor`)

    dispatch({
      type: USER_I_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_I_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteUser = (id) => async (dispatch) => {
  try {
    dispatch({
      type: USER_DELETE_REQUEST,
    })

    await axios.delete(`/api/users/${id}`)

    dispatch({
      type: USER_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: USER_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateUser = (user) => async (dispatch) => {
  try {
    dispatch({
      type: USER_UPDATE_REQUEST,
    })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const { data } = await axios.put(`/api/users/${user._id}`, user, config)

    dispatch({
      type: USER_UPDATE_SUCCESS,
    })
    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// export const verifyUser = (id) => async (dispatch, getState) => {
//   try {
//     dispatch({
//       type: USER_VERIFY_REQUEST,
//     })

//     const {
//       userLogin: { accessToken },
//     } = getState()

//     const config = {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//       },
//     }

//     await axios.get(`/api/users/${id}/confirmation/${accessToken}`, config)

//     dispatch({
//       type: USER_VERIFY_SUCCESS,
//     })
//   } catch (error) {
//     dispatch({
//       type: USER_VERIFY_FAIL,
//       payload:
//         error.response && error.response.data.message
//           ? error.response.data.message
//           : error.message,
//     })
//   }
// }

export const resendVerificationMail = (id) => async (dispatch) => {
  try {
    dispatch({
      type: USER_VERIFY_RESEND_REQUEST,
    })

    await axios.post(`/api/users/${id}/confirmation/resend`)

    dispatch({
      type: USER_VERIFY_RESEND_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: USER_VERIFY_RESEND_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const forgotPassAction = (resetToken) => async (dispatch) => {
  try {
    dispatch({
      type: USER_FORGOT_PASS_REQUEST,
    })

    const { data } = await axios.get(`/api/users/reset/${resetToken}`)

    dispatch({
      type: USER_FORGOT_PASS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_FORGOT_PASS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const resetPassAction = (resetToken, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_RESET_PASS_REQUEST,
    })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const { data } = await axios.post(
      `/api/users/reset/${resetToken}`,
      { password },
      config
    )

    dispatch({
      type: USER_RESET_PASS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_RESET_PASS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateSachivUser = (id, sachiv) => async (dispatch) => {
  try {
    dispatch({
      type: USER_UPDATE_SACHIV_REQUEST,
    })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    console.log(id, sachiv)
    const { data } = await axios.put(`/api/users/sachiv/${id}`, sachiv, config)

    dispatch({
      type: USER_UPDATE_SACHIV_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_UPDATE_SACHIV_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
