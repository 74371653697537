import React, { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import { Container } from 'react-bootstrap'

import Header from './components/header/Header'
import Loader from './components/Loader'

// OLD WAY!
// import Footer from './components/footer/Footer'
// import CourseScreen from './screens/ECourseScreen'
// import LoginScreen from './screens/LoginScreen'
// import RegisterScreen from './screens/RegisterScreen'
// import ProfileScreen from './screens/ProfileScreen'
// import CourseListScreen from './screens/CourseListScreen'
// import PaymentScreen from './screens/PaymentScreen'
// import CourseEditScreen from './screens/CourseEditScreen'
// import LandingScreen from './screens/LandingScreen'
// import PricingScreen from './screens/PricingScreen'
// import LearningScreen from './screens/LearningScreen'
// import UserListScreen from './screens/UserListScreen'
// import UserEditScreen from './screens/UserEditScreen'
// import UserVerificationScreen from './screens/UserVerificationScreen'
// import CouponListScreen from './screens/CouponListScreen'
// import CouponEditScreen from './screens/CouponEditScreen'
// import AssignmentsScreen from './screens/AssignmentsScreen'
// import AssignmentScreen from './screens/AssignmentScreen2'
// import AssignmentCreateScreen from './screens/AssignmentCreateScreen'
// import AssignmentEditScreen from './screens/AssignmentEditScreen'
// import AssignmentSolveScreen from './screens/AssignmentSolveScreen'
// import ForgotScreen from './screens/ForgotScreen'
// import EmailVerificationScreen from './screens/EmailVerificationScreen'
// import DashBoard from './screens/DashBoard'
// import CartScreen from './screens/CartScreen'
// import OrderScreen from './screens/OrderScreen'
// import SingleCourseScreen from './screens/ModifiedCourseScreen'
// import CampusProgram from './components/CampusProgram'
// import RegisterSachivScreen from './screens/RegisterSachivScreen'
// import LeadScreen from './screens/LeadScreen'
// import AssignmentAddQuestions from './screens/AssignmentAddQuestions'
// import LeadListScreen from './screens/LeadListScreen'
// import QuestionBankScreen from './screens/QuestionBankScreen'
// import QuestionCreateScreen from './screens/QuestionCreateScreen'
// import QuestionEditScreen from './screens/QuestionEditScreen'
// import PracticeScreen from './screens/PracticeScreen'

// LAZY LOAD!
const LandingScreen = lazy(() => import('./screens/LandingScreen'))
const Footer = lazy(() => import('./components/footer/Footer'))
const HomeScreen = lazy(() => import('./screens/HomeScreen'))
const CourseScreen = lazy(() => import('./screens/ECourseScreen'))
const LoginScreen = lazy(() => import('./screens/LoginScreen'))
const RegisterScreen = lazy(() => import('./screens/RegisterScreen'))
const ProfileScreen = lazy(() => import('./screens/ProfileScreen'))
const CourseListScreen = lazy(() => import('./screens/CourseListScreen'))
const PaymentScreen = lazy(() => import('./screens/PaymentScreen'))
const CourseEditScreen = lazy(() => import('./screens/CourseEditScreen'))
const PricingScreen = lazy(() => import('./screens/PricingScreen'))
const LearningScreen = lazy(() => import('./screens/LearningScreen'))
const UserListScreen = lazy(() => import('./screens/UserListScreen'))
const UserEditScreen = lazy(() => import('./screens/UserEditScreen'))
const UserVerificationScreen = lazy(() =>
  import('./screens/UserVerificationScreen')
)
const CouponListScreen = lazy(() => import('./screens/CouponListScreen'))
const CouponEditScreen = lazy(() => import('./screens/CouponEditScreen'))
const AssignmentsScreen = lazy(() => import('./screens/AssignmentsScreen'))
const AssignmentScreen = lazy(() => import('./screens/AssignmentScreen2'))
const AssignmentEditScreen = lazy(() =>
  import('./screens/AssignmentEditScreen')
)
const AssignmentCreateScreen = lazy(() =>
  import('./screens/AssignmentCreateScreen')
)
const AssignmentSolveScreen = lazy(() =>
  import('./screens/AssignmentSolveScreen')
)
const ForgotScreen = lazy(() => import('./screens/ForgotScreen'))
const EmailVerificationScreen = lazy(() =>
  import('./screens/EmailVerificationScreen')
)
const DashBoard = lazy(() => import('./screens/DashBoard'))
const CartScreen = lazy(() => import('./screens/CartScreen'))
const OrderScreen = lazy(() => import('./screens/OrderScreen'))
const SingleCourseScreen = lazy(() => import('./screens/ModifiedCourseScreen'))
const CampusProgram = lazy(() => import('./components/CampusProgram'))
const RegisterSachivScreen = lazy(() =>
  import('./screens/RegisterSachivScreen')
)
const LeadScreen = lazy(() => import('./screens/LeadScreen'))

const AssignmentAddQuestions = lazy(() =>
  import('./screens/AssignmentAddQuestions')
)
const LeadListScreen = lazy(() => import('./screens/LeadListScreen'))
const QuestionBankScreen = lazy(() => import('./screens/QuestionBankScreen'))
const QuestionCreateScreen = lazy(() =>
  import('./screens/QuestionCreateScreen')
)
const QuestionEditScreen = lazy(() => import('./screens/QuestionEditScreen'))
const PracticeScreen = lazy(() => import('./screens/PracticeScreen'))
const CategoryScreen = lazy(() => import('./screens/CategoryScreen'))
const CategoryEditScreen = lazy(() => import('./screens/CategoryEditScreen'))
const LogoutScreen = lazy(() => import('./screens/LogoutScreen'))

const tagManagerArgs = {
  gtmId: 'GTM-MB2NQJQ',
}

TagManager.initialize(tagManagerArgs)

ReactGA.initialize('UA-212288345-1')

function App() {
  return (
    <Router>
      <Route render={({ history }) => <Header history={history} />} />
      <Suspense fallback={<Loader lazy={true} />}>
        <Route
          path='/tag'
          render={(props) => {
            ReactGA.pageview(props.location.pathname)
            window.dataLayer.push({
              event: 'pageview2',
            })
            return <ProfileScreen />
          }}
        />

        {/* <main style={{ background: 'rgba(200,200,240,0.4)' }}> */}
        <main>
          <Route
            path='/'
            render={({ location, history }) => {
              ReactGA.pageview('https://codingspoon.com')

              return <LandingScreen location={location} history={history} />
            }}
            exact
          />
          {/* <Route path='/modified/:id' component={ModifiedCourseScreen} /> */}
          <Route
            path='/login'
            render={({ location, match, history }) => {
              ReactGA.pageview('https://codingspoon.com/login')

              return (
                <LoginScreen
                  location={location}
                  match={match}
                  history={history}
                />
              )
            }}
            exact
          />

          <Route
            path='/register'
            render={({ location, match, history }) => {
              ReactGA.pageview('https://codingspoon.com/register')

              return (
                <RegisterScreen
                  location={location}
                  match={match}
                  history={history}
                />
              )
            }}
            exact
          />
          <Route path='/profile2' component={DashBoard} exact />

          <Route
            path='/course/:id/assignments/:_id/questions'
            render={({ location, match, history }) => {
              ReactGA.pageview(
                'https://codingspoon.com/course/:id/assignments/:_id'
              )

              return (
                <AssignmentScreen
                  location={location}
                  match={match}
                  history={history}
                />
              )
            }}
            exact
          />

          <Route
            path='/practice'
            render={({ location, match, history }) => {
              ReactGA.pageview('https://codingspoon.com/practice')

              return (
                <PracticeScreen
                  location={location}
                  match={match}
                  history={history}
                />
              )
            }}
            exact
          />

          <Container style={{ paddingTop: '110px' }}>
            <Route
              path='/category/:id/edit'
              render={({ location, match, history }) => {
                ReactGA.pageview('https://codingspoon.com/category/edit')

                return (
                  <CategoryEditScreen
                    location={location}
                    match={match}
                    history={history}
                  />
                )
              }}
              exact
            />

            <Route
              path='/logout'
              render={({ location, match, history }) => {
                ReactGA.pageview('https://codingspoon.com/logout')

                return (
                  <LogoutScreen
                    location={location}
                    match={match}
                    history={history}
                  />
                )
              }}
              exact
            />

            <Route
              path='/category'
              render={({ location, match, history }) => {
                ReactGA.pageview('https://codingspoon.com/category')

                return (
                  <CategoryScreen
                    location={location}
                    match={match}
                    history={history}
                  />
                )
              }}
              exact
            />

            <Route
              path='/login/reset/:resetToken'
              component={ForgotScreen}
              exact
            />
            <Route
              path='/verify/:verifyToken'
              component={EmailVerificationScreen}
              exact
            />

            <Route path='/profile' component={ProfileScreen} />

            <Route
              path='/question_bank/question/:id/edit'
              render={({ location, match, history }) => {
                ReactGA.pageview(
                  `https://codingspoon.com/question_bank/question/:id/edit`
                )

                return (
                  <QuestionEditScreen
                    location={location}
                    match={match}
                    history={history}
                  />
                )
              }}
              exact
            />

            <Route
              path='/question_bank/question/new'
              render={({ location, match, history }) => {
                ReactGA.pageview(
                  `https://codingspoon.com/question_bank/question/new`
                )

                return (
                  <QuestionCreateScreen
                    location={location}
                    match={match}
                    history={history}
                  />
                )
              }}
              exact
            />
            <Route
              path='/question_bank'
              render={({ location, match, history }) => {
                ReactGA.pageview(`https://codingspoon.com/question_bank`)

                return (
                  <QuestionBankScreen
                    location={location}
                    match={match}
                    history={history}
                  />
                )
              }}
              exact
            />

            <Route
              path='/course/:id/questions/page/:pageNumber'
              render={({ location, match, history }) => {
                ReactGA.pageview(
                  `https://codingspoon.com/course/${match.params.id}/questions`
                )

                return (
                  <CourseScreen
                    location={location}
                    match={match}
                    history={history}
                  />
                )
              }}
              exact
            />
            <Route
              path='/course/:id/assignments/:_id/edit'
              component={AssignmentEditScreen}
              exact
            />
            <Route
              path='/course/:id/assignments/:_id/:assignmentId/solve'
              component={AssignmentSolveScreen}
              exact
            />
            <Route
              path='/course/:id/assignments/:_id/add_question'
              component={AssignmentAddQuestions}
              exact
            />
            <Route
              path='/course/:id/assignments/create'
              component={AssignmentCreateScreen}
              exact
            />
            <Route
              path='/course/:id/assignments'
              render={({ location, match, history }) => {
                ReactGA.pageview(
                  'https://codingspoon.com/course/:id/assignments'
                )

                return (
                  <AssignmentsScreen
                    location={location}
                    match={match}
                    history={history}
                  />
                )
              }}
              exact
            />

            <Route
              path='/singlecourse/:id'
              render={({ location, match, history }) => {
                ReactGA.pageview('Test')
                return (
                  <CourseScreen
                    location={location}
                    match={match}
                    history={history}
                  />
                )
              }}
              exact
            />

            <Route
              path='/course/:id'
              render={({ location, match, history }) => {
                ReactGA.pageview(
                  `https://codingspoon.com/course/${match.params.id}`
                )

                return (
                  <SingleCourseScreen
                    location={location}
                    match={match}
                    history={history}
                  />
                )
              }}
              exact
            />
            {/* <Route path='/singlecourse/:id' component={CourseScreen} exact /> */}

            <Route
              path='/pay/:id'
              render={({ location, match, history }) => {
                ReactGA.pageview(
                  `https://codingspoon.com/pay/${match.params.id}`
                )

                return (
                  <PaymentScreen
                    location={location}
                    match={match}
                    history={history}
                  />
                )
              }}
              exact
            />
            <Route
              path='/admin/courses/:pageNumber'
              component={CourseListScreen}
              exact
            />
            <Route path='/admin/courses' component={CourseListScreen} exact />
            <Route path='/verify' component={UserVerificationScreen} exact />
            <Route
              path='/admin/courses/:id/edit'
              component={CourseEditScreen}
              exact
            />
            <Route path='/pricing' component={PricingScreen} exact />
            <Route path='/learning' component={LearningScreen} exact />

            <Route path='/admin/userlist' component={UserListScreen} exact />
            <Route
              path='/admin/user/:id/edit'
              component={UserEditScreen}
              exact
            />

            <Route
              path='/admin/coupons/:id/edit'
              component={CouponEditScreen}
              exact
            />
            <Route path='/admin/coupons' component={CouponListScreen} exact />

            <Route
              path='/courses/search/:keyword'
              render={({ location, match, history }) => {
                ReactGA.pageview(
                  `https://codingspoon.com/courses/search/${match.params.keyword}`
                )

                return (
                  <HomeScreen
                    location={location}
                    match={match}
                    history={history}
                  />
                )
              }}
              exact
            />
            <Route
              path='/courses/search/:keyword/page/:pageNumber'
              render={({ location, match, history }) => {
                ReactGA.pageview(
                  `https://codingspoon.com/courses/search/${match.params.keyword}/page/${match.params.pageNumber}`
                )

                return (
                  <HomeScreen
                    location={location}
                    match={match}
                    history={history}
                  />
                )
              }}
              exact
            />
            <Route
              path='/courses/page/:pageNumber'
              render={({ location, match, history }) => {
                ReactGA.pageview(
                  `https://codingspoon.com/courses/page/${match.params.pageNumber}`
                )

                return (
                  <HomeScreen
                    location={location}
                    match={match}
                    history={history}
                  />
                )
              }}
              exact
            />
            <Route
              path='/register_sachiv'
              component={RegisterSachivScreen}
              exact
            />
            <Route
              path='/cart'
              render={({ location, match, history }) => {
                ReactGA.pageview('https://codingspoon.com/cart')

                return (
                  <CartScreen
                    location={location}
                    match={match}
                    history={history}
                  />
                )
              }}
              exact
            />
            <Route
              path='/order'
              render={({ location, match, history }) => {
                ReactGA.pageview('https://codingspoon.com/order')

                return (
                  <OrderScreen
                    location={location}
                    match={match}
                    history={history}
                  />
                )
              }}
              exact
            />
            <Route
              path='/free_session'
              render={({ location, match, history }) => {
                ReactGA.pageview('https://codingspoon.com/free_session')

                return (
                  <LeadScreen
                    location={location}
                    match={match}
                    history={history}
                  />
                )
              }}
              exact
            />
            <Route
              path='/leads'
              render={({ location, match, history }) => {
                ReactGA.pageview('https://codingspoon.com/leads')

                return (
                  <LeadListScreen
                    location={location}
                    match={match}
                    history={history}
                  />
                )
              }}
              exact
            />
            <Route
              path='/courses'
              render={({ location, match, history }) => {
                ReactGA.pageview('https://codingspoon.com/courses')

                return (
                  <HomeScreen
                    location={location}
                    match={match}
                    history={history}
                  />
                )
              }}
              exact
            />
          </Container>
          <Route path='/campus' component={CampusProgram} exact />
          {/* <Route component={NotFoundScreen} exact /> */}
        </main>

        <Footer />
      </Suspense>
    </Router>
  )
}

export default App
