import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
// import SearchBox from './SearchBox'
import { logout, getUserDetails } from '../../actions/userActions'
import './Header.css'

import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'
import { ImCart } from 'react-icons/im'

import Logo from '../images/logo-dark.png'
import CampusSachiv from '../images/CampusLogo.png'
import RecruitmentProposal from '../footer/RecruitmentProposal.pdf'

import Scroll from 'react-scroll'

const Header = ({ cookies, history }) => {
  const [currentPos, setCurrentPos] = React.useState(window.pageYOffset)

  var ScrollLink = Scroll.Link

  const [side, setSide] = React.useState(window.innerWidth)
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userRegister = useSelector((state) => state.userRegister)
  const { userInfo: userInfoRegister } = userRegister

  const userDetails = useSelector((state) => state.userDetails)
  const { user } = userDetails

  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart

  React.useEffect(() => {
    if (!cookies.get('_uid') && userInfo) {
      dispatch(logout())
    }
    if (userInfo || userInfoRegister) {
      if (!user?.image) {
        dispatch(getUserDetails('profile'))
      }
    }
  }, [dispatch, userInfo, user?.image, userInfoRegister, cookies])

  React.useEffect(() => {
    function resizeTracker() {
      window.addEventListener('resize', handleResize, { passive: true })

      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
    resizeTracker()

    function scrollTracker() {
      window.addEventListener('scroll', handleScroll, { passive: true })

      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }
    scrollTracker()
  }, [dispatch, currentPos])

  const handleScroll = () => {
    const position = window.pageYOffset

    setCurrentPos(position)
  }

  const handleResize = () => {
    const position = window.innerWidth

    setSide(position)
  }

  const logoutHandler = (e) => {
    e.preventDefault()
    dispatch(logout())
    setTimeout(function () {
      history.push('/login')
    }, 800)
  }

  return (
    <header className={currentPos < 60 ? 'sticky' : ''}>
      <Navbar
        expand='md'
        fixed='top'
        bg={side > 752 ? (currentPos > 120 ? 'light' : 'none') : 'light'}
        variant='light'
        collapseOnSelect
      >
        <Container>
          <Link to='/'>
            <Navbar.Brand>
              <div className='Logo-img'>
                <img
                  src={Logo}
                  width='60'
                  height='30'
                  className='header-log-1'
                  alt='Logo'
                />
              </div>
              <div className='Logo'>
                <img
                  src={Logo}
                  width='160'
                  height='40'
                  className='header-logo-2'
                  alt='Logo'
                />
              </div>
            </Navbar.Brand>
          </Link>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse
            className='justify-content-center ml-auto'
            id='responsive-navbar-nav'
          >
            <LinkContainer to='/'>
              <Nav.Link className='MainLink'>Home</Nav.Link>
            </LinkContainer>

            <Nav.Link
              href='https://codingspoon.onlineclass.site/'
              target='_blank'
              rel='noreferrer'
              className='MainLink'
            >
              Classroom
            </Nav.Link>

            <NavDropdown title='More' id='More'>
              <NavDropdown.Item
                href={RecruitmentProposal ? RecruitmentProposal : ''}
                target='_blank'
                rel='noopener noreferrer'
                className='MainLink hireLink'
                style={{ fontSize: '13px' }}
              >
                Hire From Us
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <LinkContainer to='/campus'>
                <Nav.Link className='MainLink'>
                  <img
                    src={CampusSachiv}
                    alt='Campus Program'
                    style={{ height: '35px' }}
                  ></img>
                </Nav.Link>
              </LinkContainer>
            </NavDropdown>

            {/* <LinkContainer to={RecruitmentProposal} >
              <Nav.Link className='MainLink'>Hire From Us</Nav.Link>
            </LinkContainer>
            <LinkContainer to='/contact'>
              <Nav.Link className='MainLink'>Contact</Nav.Link>
            </LinkContainer>
            <LinkContainer to='/assignments'>
              <Nav.Link className='MainLink'>Assignments</Nav.Link>
            </LinkContainer> */}
            {/* <Route render={({ history }) => <SearchBox history={history} />} /> */}
            <Navbar.Collapse className='justify-content-end ml-auto'>
              {userInfo?.isAdmin && (
                <NavDropdown title='Admin' id='admin'>
                  <LinkContainer to='/admin/userlist'>
                    <NavDropdown.Item>Users</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/courses'>
                    <NavDropdown.Item>Courses</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/coupons'>
                    <NavDropdown.Item>Coupons</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/question_bank'>
                    <NavDropdown.Item>Question Bank</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/category'>
                    <NavDropdown.Item>Categories</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}
              <LinkContainer to='/cart'>
                <Nav.Link className='MainLink'>
                  <span className='d-inline'>
                    <ImCart size={20} />
                    &nbsp; &nbsp;{cartItems.length}
                  </span>
                </Nav.Link>
              </LinkContainer>
              {user?.image ? (
                <NavDropdown
                  title={
                    <img
                      src={user?.image}
                      width='30'
                      height='30'
                      className='userImage'
                      alt='User Pic'
                    />
                  }
                  id='username'
                >
                  <LinkContainer to='/profile'>
                    <NavDropdown.Item>Profile</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/settings'>
                    <NavDropdown.Item>Settings</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item onClick={logoutHandler}>
                    Log out
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <Nav>
                  <Nav.Link
                    href='https://codingspoon.onlineclass.site/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <button className='Signup'>join us &rarr;</button>
                  </Nav.Link>
                </Nav>
              )}
            </Navbar.Collapse>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

Header.prototypes = {
  cookies: instanceOf(Cookies).isRequired,
}

export default withCookies(Header)
