import {
  COURSE_DETAILS_REQUEST,
  COURSE_DETAILS_SUCCESS,
  COURSE_DETAILS_FAIL,
  COURSE_LIST_REQUEST,
  COURSE_LIST_SUCCESS,
  COURSE_LIST_FAIL,
  COURSE_DELETE_REQUEST,
  COURSE_DELETE_FAIL,
  COURSE_DELETE_SUCCESS,
  COURSE_CREATE_REQUEST,
  COURSE_CREATE_SUCCESS,
  COURSE_CREATE_FAIL,
  COURSE_CREATE_RESET,
  COURSE_UPDATE_FAIL,
  COURSE_UPDATE_SUCCESS,
  COURSE_UPDATE_REQUEST,
  COURSE_UPDATE_RESET,
  COURSE_CREATE_REVIEW_REQUEST,
  COURSE_CREATE_REVIEW_SUCCESS,
  COURSE_CREATE_REVIEW_FAIL,
  COURSE_CREATE_REVIEW_RESET,
  COURSE_PAY_FAIL,
  COURSE_PAY_REQUEST,
  COURSE_PAY_RESET,
  COURSE_PAY_SUCCESS,
  COURSE_TOP_RATED_REQUEST,
  COURSE_TOP_RATED_SUCCESS,
  COURSE_TOP_RATED_FAIL,
  COURSE_QUESTION_REQUEST,
  COURSE_QUESTION_SUCCESS,
  COURSE_QUESTION_FAIL,
  COURSE_QUESTION_RESET,
  COURSE_ANSWER_REQUEST,
  COURSE_ANSWER_SUCCESS,
  COURSE_ANSWER_FAIL,
  COURSE_ANSWER_RESET,
  COURSE_ASSIGNMENT_CREATE_REQUEST,
  COURSE_ASSIGNMENT_CREATE_SUCCESS,
  COURSE_ASSIGNMENT_CREATE_FAIL,
  COURSE_ASSIGNMENT_CREATE_RESET,
  COURSE_ASSIGNMENT_LIST_REQUEST,
  COURSE_ASSIGNMENT_LIST_SUCCESS,
  COURSE_ASSIGNMENT_LIST_FAIL,
  COURSE_ASSIGNMENT_DELETE_REQUEST,
  COURSE_ASSIGNMENT_DELETE_SUCCESS,
  COURSE_ASSIGNMENT_DELETE_FAIL,
  COURSE_ASSIGNMENT_UPDATE_REQUEST,
  COURSE_ASSIGNMENT_UPDATE_SUCCESS,
  COURSE_ASSIGNMENT_UPDATE_FAIL,
  COURSE_ASSIGNMENT_UPDATE_RESET,
  COURSE_ASSIGNMENT_DETAILS_REQUEST,
  COURSE_ASSIGNMENT_DETAILS_SUCCESS,
  COURSE_ASSIGNMENT_DETAILS_FAIL,
  COURSE_ASSIGNMENT_DETAILS_RESET,
  COURSE_ASSIGNMENT_SOLVE_REQUEST,
  COURSE_ASSIGNMENT_SOLVE_SUCCESS,
  COURSE_ASSIGNMENT_SOLVE_FAIL,
  COURSE_ASSIGNMENT_SOLVE_RESET,
  COURSE_ASSIGNMENT_ADD_QUESTIONS_REQUEST,
  COURSE_ASSIGNMENT_ADD_QUESTIONS_SUCCESS,
  COURSE_ASSIGNMENT_ADD_QUESTIONS_FAIL,
  COURSE_ASSIGNMENT_ADD_QUESTIONS_RESET,
  COURSE_ASSIGNMENT_QUESTIONS_LIST_REQUEST,
  COURSE_ASSIGNMENT_QUESTIONS_LIST_SUCCESS,
  COURSE_ASSIGNMENT_QUESTIONS_LIST_FAIL,
  COURSE_INSTRUCTOR_UPDATE_REQUEST,
  COURSE_INSTRUCTOR_UPDATE_SUCCESS,
  COURSE_INSTRUCTOR_UPDATE_FAIL,
  COURSE_INSTRUCTOR_UPDATE_RESET,
} from '../constants/courseConstants'

export const courseListReducer = (state = { courses: [] }, action) => {
  switch (action.type) {
    case COURSE_LIST_REQUEST:
      return { loading: true, courses: [] }
    case COURSE_LIST_SUCCESS:
      return {
        loading: false,
        courses: action.payload.courses,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case COURSE_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const courseTopRatedReducer = (state = { courses: [] }, action) => {
  switch (action.type) {
    case COURSE_TOP_RATED_REQUEST:
      return { loading: true, courses: [] }
    case COURSE_TOP_RATED_SUCCESS:
      return {
        loading: false,
        courses: action.payload,
      }
    case COURSE_TOP_RATED_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const courseDetailsReducer = (
  state = { course: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case COURSE_DETAILS_REQUEST:
      return { loading: true, ...state }
    case COURSE_DETAILS_SUCCESS:
      return { loading: false, course: action.payload }
    case COURSE_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const courseReviewCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_CREATE_REVIEW_REQUEST:
      return { loading: true }
    case COURSE_CREATE_REVIEW_SUCCESS:
      return { loading: false, success: true }
    case COURSE_CREATE_REVIEW_FAIL:
      return { loading: false, error: action.payload }
    case COURSE_CREATE_REVIEW_RESET:
      return {}
    default:
      return state
  }
}

export const courseDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_DELETE_REQUEST:
      return { loading: true, ...state }
    case COURSE_DELETE_SUCCESS:
      return { loading: false, success: true }
    case COURSE_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const coursePayReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_PAY_REQUEST:
      return { loading: true, ...state }
    case COURSE_PAY_SUCCESS:
      return { loading: false, success: true, course: action.payload }
    case COURSE_PAY_FAIL:
      return { loading: false, error: action.payload }
    case COURSE_PAY_RESET:
      return {}
    default:
      return state
  }
}

export const courseCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_CREATE_REQUEST:
      return { loading: true, ...state }
    case COURSE_CREATE_SUCCESS:
      return { loading: false, success: true, course: action.payload }
    case COURSE_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case COURSE_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const courseUpdateReducer = (state = { course: {} }, action) => {
  switch (action.type) {
    case COURSE_UPDATE_REQUEST:
      return { loading: true, ...state }
    case COURSE_UPDATE_SUCCESS:
      return { loading: false, success: true, course: action.payload }
    case COURSE_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case COURSE_UPDATE_RESET:
      return { course: {} }
    default:
      return state
  }
}

export const courseUpdateInstructorReducer = (
  state = { course: {} },
  action
) => {
  switch (action.type) {
    case COURSE_INSTRUCTOR_UPDATE_REQUEST:
      return { loading: true, ...state }
    case COURSE_INSTRUCTOR_UPDATE_SUCCESS:
      return { loading: false, success: true, course: action.payload }
    case COURSE_INSTRUCTOR_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case COURSE_INSTRUCTOR_UPDATE_RESET:
      return { course: {} }
    default:
      return state
  }
}

export const courseCreateQuestionReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_QUESTION_REQUEST:
      return { loading: true }
    case COURSE_QUESTION_SUCCESS:
      return { loading: false, success: true }
    case COURSE_QUESTION_FAIL:
      return { loading: false, error: action.payload }
    case COURSE_QUESTION_RESET:
      return {}
    default:
      return state
  }
}

export const courseCreateAnswerReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_ANSWER_REQUEST:
      return { loading: true }
    case COURSE_ANSWER_SUCCESS:
      return { loading: false, success: true }
    case COURSE_ANSWER_FAIL:
      return { loading: false, error: action.payload }
    case COURSE_ANSWER_RESET:
      return {}
    default:
      return state
  }
}

export const courseCreateAssignmentReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_ASSIGNMENT_CREATE_REQUEST:
      return { loading: true }
    case COURSE_ASSIGNMENT_CREATE_SUCCESS:
      return { loading: false, success: true }
    case COURSE_ASSIGNMENT_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case COURSE_ASSIGNMENT_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const courseAddQuestionsToAssignmentReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_ASSIGNMENT_ADD_QUESTIONS_REQUEST:
      return { loading: true }
    case COURSE_ASSIGNMENT_ADD_QUESTIONS_SUCCESS:
      return { loading: false, success: true }
    case COURSE_ASSIGNMENT_ADD_QUESTIONS_FAIL:
      return { loading: false, error: action.payload }
    case COURSE_ASSIGNMENT_ADD_QUESTIONS_RESET:
      return {}
    default:
      return state
  }
}

export const courseListAssignmentReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_ASSIGNMENT_LIST_REQUEST:
      return { loading: true }
    case COURSE_ASSIGNMENT_LIST_SUCCESS:
      return { loading: false, success: true, assignments: action.payload }
    case COURSE_ASSIGNMENT_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const courseListAssignmentQuestionsReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_ASSIGNMENT_QUESTIONS_LIST_REQUEST:
      return { loading: true }
    case COURSE_ASSIGNMENT_QUESTIONS_LIST_SUCCESS:
      return { loading: false, success: true, assignment: action.payload }
    case COURSE_ASSIGNMENT_QUESTIONS_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const courseDeleteAssignmentReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_ASSIGNMENT_DELETE_REQUEST:
      return { loading: true }
    case COURSE_ASSIGNMENT_DELETE_SUCCESS:
      return { loading: false, success: true }
    case COURSE_ASSIGNMENT_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const courseUpdateAssignmentReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_ASSIGNMENT_UPDATE_REQUEST:
      return { loading: true, ...state }
    case COURSE_ASSIGNMENT_UPDATE_SUCCESS:
      return { loading: false, success: true, assignment: action.payload }
    case COURSE_ASSIGNMENT_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case COURSE_ASSIGNMENT_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const courseAssignmentDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_ASSIGNMENT_DETAILS_REQUEST:
      return { loading: true }
    case COURSE_ASSIGNMENT_DETAILS_SUCCESS:
      return { loading: false, success: true, assignment: action.payload }
    case COURSE_ASSIGNMENT_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    case COURSE_ASSIGNMENT_DETAILS_RESET:
      return {}
    default:
      return state
  }
}

export const courseSolveAssignmentReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_ASSIGNMENT_SOLVE_REQUEST:
      return { loading: true }
    case COURSE_ASSIGNMENT_SOLVE_SUCCESS:
      return { loading: false, success: true, result: action.payload }
    case COURSE_ASSIGNMENT_SOLVE_FAIL:
      return { loading: false, error: action.payload }
    case COURSE_ASSIGNMENT_SOLVE_RESET:
      return {}
    default:
      return state
  }
}
