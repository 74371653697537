export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'
export const USER_REGISTER_RESET = 'USER_REGISTER_RESET'

export const USER_DETAILS_BY_ADMIN_REQUEST = 'USER_DETAILS_BY_ADMIN_REQUEST'
export const USER_DETAILS_BY_ADMIN_SUCCESS = 'USER_DETAILS_BY_ADMIN_SUCCESS'
export const USER_DETAILS_BY_ADMIN_FAIL = 'USER_DETAILS_BY_ADMIN_FAIL'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET'

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST'
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS'
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL'
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET'

export const USER_UPDATE_SACHIV_REQUEST = 'USER_UPDATE_SACHIV_REQUEST'
export const USER_UPDATE_SACHIV_SUCCESS = 'USER_UPDATE_SACHIV_SUCCESS'
export const USER_UPDATE_SACHIV_FAIL = 'USER_UPDATE_SACHIV_FAIL'
export const USER_UPDATE_SACHIV_RESET = 'USER_UPDATE_SACHIV_RESET'

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_RESET = 'USER_LIST_RESET'

export const USER_I_LIST_REQUEST = 'USER_I_LIST_REQUEST'
export const USER_I_LIST_SUCCESS = 'USER_I_LIST_SUCCESS'
export const USER_I_LIST_FAIL = 'USER_I_LIST_FAIL'
export const USER_I_LIST_RESET = 'USER_I_LIST_RESET'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'

export const USER_VERIFY_REQUEST = 'USER_VERIFY_REQUEST'
export const USER_VERIFY_SUCCESS = 'USER_VERIFY_SUCCESS'
export const USER_VERIFY_FAIL = 'USER_VERIFY_FAIL'

export const USER_VERIFY_RESEND_REQUEST = 'USER_VERIFY_RESEND_REQUEST'
export const USER_VERIFY_RESEND_SUCCESS = 'USER_VERIFY_RESEND_SUCCESS'
export const USER_VERIFY_RESEND_FAIL = 'USER_VERIFY_RESEND_FAIL'

export const USER_FORGOT_PASS_REQUEST = 'USER_FORGOT_PASS_REQUEST'
export const USER_FORGOT_PASS_SUCCESS = 'USER_FORGOT_PASS_SUCCESS'
export const USER_FORGOT_PASS_FAIL = 'USER_FORGOT_PASS_FAIL'

export const USER_RESET_PASS_REQUEST = 'USER_RESET_PASS_REQUEST'
export const USER_RESET_PASS_SUCCESS = 'USER_RESET_PASS_SUCCESS'
export const USER_RESET_PASS_FAIL = 'USER_RESET_PASS_FAIL'
