import {
  MAIL_FEEDBACK_FAIL,
  MAIL_FEEDBACK_REQUEST,
  MAIL_FEEDBACK_SUCCESS,
  MAIL_FEEDBACK_RESET,
  MAIL_CONTACT_FAIL,
  MAIL_CONTACT_REQUEST,
  MAIL_CONTACT_SUCCESS,
  MAIL_CONTACT_RESET,
  MAIL_RESET_FAIL,
  MAIL_RESET_REQUEST,
  MAIL_RESET_SUCCESS,
  MAIL_RESET_RESET,
  MAIL_VERIFICATION_FAIL,
  MAIL_VERIFICATION_REQUEST,
  MAIL_VERIFICATION_SUCCESS,
} from '../constants/mailConstants'

export const mailFeedbackReducer = (state = {}, action) => {
  switch (action.type) {
    case MAIL_FEEDBACK_REQUEST:
      return { loading: true }
    case MAIL_FEEDBACK_SUCCESS:
      return { loading: false, success: true }
    case MAIL_FEEDBACK_FAIL:
      return { loading: false, error: action.payload }
    case MAIL_FEEDBACK_RESET:
      return {}
    default:
      return state
  }
}

export const mailContactReducer = (state = {}, action) => {
  switch (action.type) {
    case MAIL_CONTACT_REQUEST:
      return { loading: true }
    case MAIL_CONTACT_SUCCESS:
      return { loading: false, success: true }
    case MAIL_CONTACT_FAIL:
      return { loading: false, error: action.payload }
    case MAIL_CONTACT_RESET:
      return {}
    default:
      return state
  }
}

export const mailResetReducer = (state = {}, action) => {
  switch (action.type) {
    case MAIL_RESET_REQUEST:
      return { loading: true }
    case MAIL_RESET_SUCCESS:
      return { loading: false, success: true, msg: action.payload }
    case MAIL_RESET_FAIL:
      return { loading: false, error: action.payload }
    case MAIL_RESET_RESET:
      return {}
    default:
      return state
  }
}

export const mailVerificationReducer = (state = {}, action) => {
  switch (action.type) {
    case MAIL_VERIFICATION_REQUEST:
      return { loading: true }
    case MAIL_VERIFICATION_SUCCESS:
      return { loading: false, success: true, data: action.payload }
    case MAIL_VERIFICATION_FAIL:
      return { loading: false, error: action.payload }

    default:
      return state
  }
}
