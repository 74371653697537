import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
  userDetailsByAdminReducer,
  userVerifyReducer,
  userVerifyResendReducer,
  userForgotPassReducer,
  userIListReducer,
  userResetPassReducer,
  userUpdateSachivReducer,
} from './reducers/userReducers'

import {
  courseDetailsReducer,
  courseListReducer,
  courseReviewCreateReducer,
  coursePayReducer,
  courseCreateReducer,
  courseUpdateReducer,
  courseDeleteReducer,
  courseTopRatedReducer,
  courseCreateQuestionReducer,
  courseCreateAnswerReducer,
  courseCreateAssignmentReducer,
  courseListAssignmentReducer,
  courseDeleteAssignmentReducer,
  courseUpdateAssignmentReducer,
  courseAssignmentDetailsReducer,
  courseSolveAssignmentReducer,
  courseAddQuestionsToAssignmentReducer,
  courseListAssignmentQuestionsReducer,
  courseUpdateInstructorReducer,
} from './reducers/courseReducers'

import {
  batchDetailsReducer,
  batchListReducer,
  batchReviewCreateReducer,
  batchPayReducer,
  batchDemoReducer,
  batchDemoOverReducer,
  batchCreateReducer,
  batchUpdateReducer,
  batchDeleteReducer,
  batchTopRatedReducer,
  batchCreateQuestionReducer,
  batchCreateAnswerReducer,
  batchCreateAssignmentReducer,
  batchListAssignmentReducer,
  batchDeleteAssignmentReducer,
  batchUpdateAssignmentReducer,
  batchAssignmentDetailsReducer,
  batchSolveAssignmentReducer,
} from './reducers/batchReducers'

import {
  mailContactReducer,
  mailFeedbackReducer,
  mailResetReducer,
  mailVerificationReducer,
} from './reducers/mailReducers'

import {
  compilerSubmissionReducer,
  compilerSubmissionResultsReducer,
  compilerMultiSubmissionReducer,
  compilerMultiSubmissionResultsReducer,
} from './reducers/compilerReducers'

import {
  couponListReducer,
  couponCreateReducer,
  couponDetailsReducer,
  couponDeleteReducer,
  updateCouponReducer,
  applyCouponReducer,
} from './reducers/couponReducers'

import { cartReducer } from './reducers/cartReducers'

import {
  orderCreateReducer,
  orderDetailsReducer,
  orderPayReducer,
  orderListMyReducer,
  orderListReducer,
  orderDeleteReducer,
} from './reducers/orderReducers'

import {
  leadCreateReducer,
  leadsDetailReducer,
  leadUpdateReducer,
  leadDeleteReducer,
  addMyLeadReducer,
  inactiveLeadsReducer,
  myLeadsReducer,
  editLeadReducer,
} from './reducers/leadsReducers'

import {
  listQuestionsReducer,
  createQuestionReducer,
  questionDetailsReducer,
  updateQuestionReducer,
  practiceQuestionDetailsReducer,
  solvePracticeQuestionReducer,
  getPracticeQuestionsReducer,
} from './reducers/questionBankReducers'

import {
  categoryCreateReducer,
  categoryUpdateReducer,
  categoryDeleteReducer,
  listCategoriesReducer,
  subCategoryCreateReducer,
  listSubCategoriesReducer,
  topicCreateReducer,
  listTopicsReducer,
  subjectCreateReducer,
  listSubjectsReducer,
  categoryDetailsReducer,
} from './reducers/categoryReducers'

const reducer = combineReducers({
  //Cart
  cart: cartReducer,
  //Order
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  orderListMy: orderListMyReducer,
  orderList: orderListReducer,
  orderDelete: orderDeleteReducer,
  //User
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userDetailsByAdmin: userDetailsByAdminReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userIList: userIListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  userVerify: userVerifyReducer,
  userVerifyResend: userVerifyResendReducer,
  userForgotPass: userForgotPassReducer,
  userResetPass: userResetPassReducer,
  userUpdateSachiv: userUpdateSachivReducer,
  //Course
  courseList: courseListReducer,
  courseDetails: courseDetailsReducer,
  courseReviewCreate: courseReviewCreateReducer,
  coursePay: coursePayReducer,
  courseCreate: courseCreateReducer,
  courseUpdate: courseUpdateReducer,
  courseDelete: courseDeleteReducer,
  courseTopRated: courseTopRatedReducer,
  courseCreateQuestion: courseCreateQuestionReducer,
  courseCreateAnswer: courseCreateAnswerReducer,
  courseCreateAssignment: courseCreateAssignmentReducer,
  courseListAssignment: courseListAssignmentReducer,
  courseListAssignmentQuestions: courseListAssignmentQuestionsReducer,
  courseDeleteAssignment: courseDeleteAssignmentReducer,
  courseUpdateAssignment: courseUpdateAssignmentReducer,
  courseAssignmentDetails: courseAssignmentDetailsReducer,
  courseSolveAssignment: courseSolveAssignmentReducer,
  courseAddQuestionsToAssignment: courseAddQuestionsToAssignmentReducer,
  courseUpdateInstructor: courseUpdateInstructorReducer,
  //Mail
  mailFeedback: mailFeedbackReducer,
  mailContact: mailContactReducer,
  mailReset: mailResetReducer,
  mailVerification: mailVerificationReducer,
  //Coupon
  couponList: couponListReducer,
  couponCreate: couponCreateReducer,
  couponDetails: couponDetailsReducer,
  couponDelete: couponDeleteReducer,
  updateCoupon: updateCouponReducer,
  applyCoupon: applyCouponReducer,
  //Compiler
  compilerSubmission: compilerSubmissionReducer,
  compilerSubmissionResults: compilerSubmissionResultsReducer,
  compilerMultiSubmissionResults: compilerMultiSubmissionResultsReducer,
  compilerMultiSubmission: compilerMultiSubmissionReducer,
  //Batch
  batchList: batchListReducer,
  batchDetails: batchDetailsReducer,
  batchReviewCreate: batchReviewCreateReducer,
  batchPay: batchPayReducer,
  batchDemo: batchDemoReducer,
  batchDemoOver: batchDemoOverReducer,
  batchCreate: batchCreateReducer,
  batchUpdate: batchUpdateReducer,
  batchDelete: batchDeleteReducer,
  batchTopRated: batchTopRatedReducer,
  batchCreateQuestion: batchCreateQuestionReducer,
  batchCreateAnswer: batchCreateAnswerReducer,
  batchCreateAssignment: batchCreateAssignmentReducer,
  batchListAssignment: batchListAssignmentReducer,
  batchDeleteAssignment: batchDeleteAssignmentReducer,
  batchUpdateAssignment: batchUpdateAssignmentReducer,
  batchAssignmentDetails: batchAssignmentDetailsReducer,
  batchSolveAssignment: batchSolveAssignmentReducer,
  //Leads
  leadCreate: leadCreateReducer,
  leadsDetail: leadsDetailReducer,
  leadUpdate: leadUpdateReducer,
  leadDelete: leadDeleteReducer,
  addMyLead: addMyLeadReducer,
  inactiveLeads: inactiveLeadsReducer,
  myLeads: myLeadsReducer,
  editLead: editLeadReducer,
  //Questions
  listQuestions: listQuestionsReducer,
  createQuestion: createQuestionReducer,
  updateQuestion: updateQuestionReducer,
  questionDetails: questionDetailsReducer,
  practiceQuestionDetails: practiceQuestionDetailsReducer,
  solvePracticeQuestion: solvePracticeQuestionReducer,
  getPracticeQuestions: getPracticeQuestionsReducer,
  //Category
  categoryCreate: categoryCreateReducer,
  listCategories: listCategoriesReducer,
  categoryUpdate: categoryUpdateReducer,
  categoryDelete: categoryDeleteReducer,
  subCategoryCreate: subCategoryCreateReducer,
  listSubCategories: listSubCategoriesReducer,
  topicCreate: topicCreateReducer,
  listTopics: listTopicsReducer,
  subjectCreate: subjectCreateReducer,
  listSubjects: listSubjectsReducer,
  categoryDetails: categoryDetailsReducer,
})

const cartItemsFromStorage = localStorage.getItem('cartItems')
  ? JSON.parse(localStorage.getItem('cartItems'))
  : []

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null

const initialState = {
  cart: {
    cartItems: cartItemsFromStorage,
  },
  userLogin: { userInfo: userInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
