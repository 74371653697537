import {
  COMPILER_SUBMISSION_REQUEST,
  COMPILER_SUBMISSION_SUCCESS,
  COMPILER_SUBMISSION_FAIL,
  COMPILER_SUBMISSION_RESET,
  COMPILER_SUBMISSION_RESULTS_REQUEST,
  COMPILER_SUBMISSION_RESULTS_SUCCESS,
  COMPILER_SUBMISSION_RESULTS_FAIL,
  COMPILER_SUBMISSION_RESULTS_RESET,
  COMPILER_SUBMISSION_RESULTS_PROCESSING,
  COMPILER_MULTI_SUBMISSION_RESULTS_REQUEST,
  COMPILER_MULTI_SUBMISSION_RESULTS_SUCCESS,
  COMPILER_MULTI_SUBMISSION_RESULTS_FAIL,
  COMPILER_MULTI_SUBMISSION_RESULTS_RESET,
  COMPILER_MULTI_SUBMISSION_RESULTS_PROCESSING,
  COMPILER_MULTI_SUBMISSION_REQUEST,
  COMPILER_MULTI_SUBMISSION_SUCCESS,
  COMPILER_MULTI_SUBMISSION_FAIL,
  COMPILER_MULTI_SUBMISSION_RESET,
} from '../constants/compilerConstants'

export const compilerSubmissionReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPILER_SUBMISSION_REQUEST:
      return { loading: true }
    case COMPILER_SUBMISSION_SUCCESS:
      return { loading: false, success: true, response: action.payload }
    case COMPILER_SUBMISSION_FAIL:
      return { loading: false, error: action.payload }
    case COMPILER_SUBMISSION_RESET:
      return {}
    default:
      return state
  }
}

export const compilerSubmissionResultsReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPILER_SUBMISSION_RESULTS_REQUEST:
      return { loading: true }
    case COMPILER_SUBMISSION_RESULTS_PROCESSING:
      return { loading: false, status: action.payload }
    case COMPILER_SUBMISSION_RESULTS_SUCCESS:
      return { loading: false, success: true, data: action.payload }
    case COMPILER_SUBMISSION_RESULTS_FAIL:
      return { loading: false, error: action.payload }

    case COMPILER_SUBMISSION_RESULTS_RESET:
      return {}
    default:
      return state
  }
}

export const compilerMultiSubmissionResultsReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPILER_MULTI_SUBMISSION_RESULTS_REQUEST:
      return { loading: true }
    case COMPILER_MULTI_SUBMISSION_RESULTS_PROCESSING:
      return { loading: false, status: action.payload }
    case COMPILER_MULTI_SUBMISSION_RESULTS_SUCCESS:
      return { loading: false, success: true, data: action.payload }
    case COMPILER_MULTI_SUBMISSION_RESULTS_FAIL:
      return { loading: false, error: action.payload }

    case COMPILER_MULTI_SUBMISSION_RESULTS_RESET:
      return {}
    default:
      return state
  }
}

export const compilerMultiSubmissionReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPILER_MULTI_SUBMISSION_REQUEST:
      return { loading: true }
    case COMPILER_MULTI_SUBMISSION_SUCCESS:
      return { loading: false, success: true, response: action.payload }
    case COMPILER_MULTI_SUBMISSION_FAIL:
      return { loading: false, error: action.payload }
    case COMPILER_MULTI_SUBMISSION_RESET:
      return {}
    default:
      return state
  }
}
