export const BATCH_LIST_REQUEST = 'BATCH_LIST_REQUEST'
export const BATCH_LIST_SUCCESS = 'BATCH_LIST_SUCCESS'
export const BATCH_LIST_FAIL = 'BATCH_LIST_FAIL'

export const BATCH_DETAILS_REQUEST = 'BATCH_DETAILS_REQUEST'
export const BATCH_DETAILS_SUCCESS = 'BATCH_DETAILS_SUCCESS'
export const BATCH_DETAILS_FAIL = 'BATCH_DETAILS_FAIL'

export const BATCH_DELETE_REQUEST = 'BATCH_DELETE_REQUEST'
export const BATCH_DELETE_SUCCESS = 'BATCH_DELETE_SUCCESS'
export const BATCH_DELETE_FAIL = 'BATCH_DELETE_FAIL'

export const BATCH_CREATE_REQUEST = 'BATCH_CREATE_REQUEST'
export const BATCH_CREATE_SUCCESS = 'BATCH_CREATE_SUCCESS'
export const BATCH_CREATE_FAIL = 'BATCH_CREATE_FAIL'
export const BATCH_CREATE_RESET = 'BATCH_CREATE_RESET'

export const BATCH_UPDATE_REQUEST = 'BATCH_UPDATE_REQUEST'
export const BATCH_UPDATE_SUCCESS = 'BATCH_UPDATE_SUCCESS'
export const BATCH_UPDATE_FAIL = 'BATCH_UPDATE_FAIL'
export const BATCH_UPDATE_RESET = 'BATCH_UPDATE_RESET'

export const BATCH_CREATE_REVIEW_REQUEST = 'BATCH_CREATE_REVIEW_REQUEST'
export const BATCH_CREATE_REVIEW_SUCCESS = 'BATCH_CREATE_REVIEW_SUCCESS'
export const BATCH_CREATE_REVIEW_FAIL = 'BATCH_CREATE_REVIEW_FAIL'
export const BATCH_CREATE_REVIEW_RESET = 'BATCH_CREATE_REVIEW_RESET'

export const BATCH_DEMO_OVER_REQUEST = 'BATCH_DEMO_OVER_REQUEST'
export const BATCH_DEMO_OVER_SUCCESS = 'BATCH_DEMO_OVER_SUCCESS'
export const BATCH_DEMO_OVER_FAIL = 'BATCH_DEMO_OVER_FAIL'

export const BATCH_DEMO_REQUEST = 'BATCH_DEMO_REQUEST'
export const BATCH_DEMO_SUCCESS = 'BATCH_DEMO_SUCCESS'
export const BATCH_DEMO_FAIL = 'BATCH_DEMO_FAIL'
export const BATCH_DEMO_RESET = 'BATCH_DEMO_RESET'

export const BATCH_PAY_REQUEST = 'BATCH_PAY_REQUEST'
export const BATCH_PAY_SUCCESS = 'BATCH_PAY_SUCCESS'
export const BATCH_PAY_FAIL = 'BATCH_PAY_FAIL'
export const BATCH_PAY_RESET = 'BATCH_PAY_RESET'

export const BATCH_TOP_RATED_REQUEST = 'BATCH_TOP_RATED_REQUEST'
export const BATCH_TOP_RATED_SUCCESS = 'BATCH_TOP_RATED_SUCCESS'
export const BATCH_TOP_RATED_FAIL = 'BATCH_TOP_RATED_FAIL'

export const BATCH_QUESTION_REQUEST = 'BATCH_QUESTION_REQUEST'
export const BATCH_QUESTION_SUCCESS = 'BATCH_QUESTION_SUCCESS'
export const BATCH_QUESTION_FAIL = 'BATCH_QUESTION_FAIL'
export const BATCH_QUESTION_RESET = 'BATCH_QUESTION_RESET'

export const BATCH_ANSWER_REQUEST = 'BATCH_ANSWER_REQUEST'
export const BATCH_ANSWER_SUCCESS = 'BATCH_ANSWER_SUCCESS'
export const BATCH_ANSWER_FAIL = 'BATCH_ANSWER_FAIL'
export const BATCH_ANSWER_RESET = 'BATCH_ANSWER_RESET'

export const BATCH_ASSIGNMENT_CREATE_REQUEST = 'BATCH_ASSIGNMENT_CREATE_REQUEST'
export const BATCH_ASSIGNMENT_CREATE_SUCCESS = 'BATCH_ASSIGNMENT_CREATE_SUCCESS'
export const BATCH_ASSIGNMENT_CREATE_FAIL = 'BATCH_ASSIGNMENT_CREATE_FAIL'
export const BATCH_ASSIGNMENT_CREATE_RESET = 'BATCH_ASSIGNMENT_CREATE_RESET'

export const BATCH_ASSIGNMENT_LIST_REQUEST = 'BATCH_ASSIGNMENT_LIST_REQUEST'
export const BATCH_ASSIGNMENT_LIST_SUCCESS = 'BATCH_ASSIGNMENT_LIST_SUCCESS'
export const BATCH_ASSIGNMENT_LIST_FAIL = 'BATCH_ASSIGNMENT_LIST_FAIL'

export const BATCH_ASSIGNMENT_DELETE_REQUEST = 'BATCH_ASSIGNMENT_DELETE_REQUEST'
export const BATCH_ASSIGNMENT_DELETE_SUCCESS = 'BATCH_ASSIGNMENT_DELETE_SUCCESS'
export const BATCH_ASSIGNMENT_DELETE_FAIL = 'BATCH_ASSIGNMENT_DELETE_FAIL'

export const BATCH_ASSIGNMENT_UPDATE_REQUEST = 'BATCH_ASSIGNMENT_UPDATE_REQUEST'
export const BATCH_ASSIGNMENT_UPDATE_SUCCESS = 'BATCH_ASSIGNMENT_UPDATE_SUCCESS'
export const BATCH_ASSIGNMENT_UPDATE_FAIL = 'BATCH_ASSIGNMENT_UPDATE_FAIL'
export const BATCH_ASSIGNMENT_UPDATE_RESET = 'BATCH_ASSIGNMENT_UPDATE_RESET'

export const BATCH_ASSIGNMENT_DETAILS_REQUEST =
  'BATCH_ASSIGNMENT_DETAILS_REQUEST'
export const BATCH_ASSIGNMENT_DETAILS_SUCCESS =
  'BATCH_ASSIGNMENT_DETAILS_SUCCESS'
export const BATCH_ASSIGNMENT_DETAILS_FAIL = 'BATCH_ASSIGNMENT_DETAILS_FAIL'

export const BATCH_ASSIGNMENT_SOLVE_REQUEST = 'BATCH_ASSIGNMENT_SOLVE_REQUEST'
export const BATCH_ASSIGNMENT_SOLVE_SUCCESS = 'BATCH_ASSIGNMENT_SOLVE_SUCCESS'
export const BATCH_ASSIGNMENT_SOLVE_FAIL = 'BATCH_ASSIGNMENT_SOLVE_FAIL'
export const BATCH_ASSIGNMENT_SOLVE_RESET = 'BATCH_ASSIGNMENT_SOLVE_RESET'
