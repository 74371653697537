import {
  USER_DELETE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_RESET,
  USER_DETAILS_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_RESET,
  USER_LIST_SUCCESS,
  USER_I_LIST_FAIL,
  USER_I_LIST_REQUEST,
  USER_I_LIST_RESET,
  USER_I_LIST_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_RESET,
  USER_UPDATE_FAIL,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_REQUEST,
  USER_UPDATE_RESET,
  USER_UPDATE_SUCCESS,
  USER_DETAILS_BY_ADMIN_FAIL,
  USER_DETAILS_BY_ADMIN_REQUEST,
  USER_DETAILS_BY_ADMIN_SUCCESS,
  USER_UPDATE_PROFILE_RESET,
  USER_VERIFY_FAIL,
  USER_VERIFY_REQUEST,
  USER_VERIFY_SUCCESS,
  USER_VERIFY_RESEND_FAIL,
  USER_VERIFY_RESEND_REQUEST,
  USER_VERIFY_RESEND_SUCCESS,
  USER_FORGOT_PASS_FAIL,
  USER_FORGOT_PASS_REQUEST,
  USER_FORGOT_PASS_SUCCESS,
  USER_RESET_PASS_FAIL,
  USER_RESET_PASS_REQUEST,
  USER_RESET_PASS_SUCCESS,
  USER_UPDATE_SACHIV_FAIL,
  USER_UPDATE_SACHIV_REQUEST,
  USER_UPDATE_SACHIV_SUCCESS,
  USER_UPDATE_SACHIV_RESET,
} from '../constants/userConstants'

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true }
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload }
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload }
    case USER_LOGOUT:
      return {}
    default:
      return state
  }
}

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true }
    case USER_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload }
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload }
    case USER_REGISTER_RESET:
      return {}
    default:
      return state
  }
}

export const userDetailsReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { ...state, loading: true }
    case USER_DETAILS_SUCCESS:
      return { loading: false, user: action.payload }
    case USER_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    case USER_DETAILS_RESET:
      return { user: {} }
    default:
      return state
  }
}

export const userDetailsByAdminReducer = (
  state = { userByAdmin: {} },
  action
) => {
  switch (action.type) {
    case USER_DETAILS_BY_ADMIN_REQUEST:
      return { ...state, loading: true }
    case USER_DETAILS_BY_ADMIN_SUCCESS:
      return { loading: false, userByAdmin: action.payload }
    case USER_DETAILS_BY_ADMIN_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userUpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true }
    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload }
    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload }
    case USER_UPDATE_PROFILE_RESET:
      return {}
    default:
      return state
  }
}

export const userUpdateSachivReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_SACHIV_REQUEST:
      return { loading: true }
    case USER_UPDATE_SACHIV_SUCCESS:
      return { loading: false, success: true, data: action.payload }
    case USER_UPDATE_SACHIV_FAIL:
      return { loading: false, error: action.payload }
    case USER_UPDATE_SACHIV_RESET:
      return {}
    default:
      return state
  }
}

export const userListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true }
    case USER_LIST_SUCCESS:
      return { loading: false, users: action.payload }
    case USER_LIST_FAIL:
      return { loading: false, error: action.payload }
    case USER_LIST_RESET:
      return { users: [] }
    default:
      return state
  }
}

export const userIListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_I_LIST_REQUEST:
      return { loading: true }
    case USER_I_LIST_SUCCESS:
      return { loading: false, users: action.payload }
    case USER_I_LIST_FAIL:
      return { loading: false, error: action.payload }
    case USER_I_LIST_RESET:
      return { users: [] }
    default:
      return state
  }
}

export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return { loading: true }
    case USER_DELETE_SUCCESS:
      return { loading: false, success: true }
    case USER_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userUpdateReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true }
    case USER_UPDATE_SUCCESS:
      return { loading: false, success: true }
    case USER_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case USER_UPDATE_RESET:
      return { user: {} }
    default:
      return state
  }
}

export const userVerifyReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_VERIFY_REQUEST:
      return { loading: true }
    case USER_VERIFY_SUCCESS:
      return { loading: false, success: true }
    case USER_VERIFY_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userVerifyResendReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_VERIFY_RESEND_REQUEST:
      return { loading: true }
    case USER_VERIFY_RESEND_SUCCESS:
      return { loading: false, success: true }
    case USER_VERIFY_RESEND_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userForgotPassReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_FORGOT_PASS_REQUEST:
      return { loading: true }
    case USER_FORGOT_PASS_SUCCESS:
      return { loading: false, success: true, user: action.payload }
    case USER_FORGOT_PASS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userResetPassReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_RESET_PASS_REQUEST:
      return { loading: true }
    case USER_RESET_PASS_SUCCESS:
      return { loading: false, success: true, data: action.payload }
    case USER_RESET_PASS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
