import {
  QUESTION_CREATE_REQUEST,
  QUESTION_CREATE_SUCCESS,
  QUESTION_CREATE_FAIL,
  QUESTION_CREATE_RESET,
  QUESTION_LIST_REQUEST,
  QUESTION_LIST_SUCCESS,
  QUESTION_LIST_FAIL,
  QUESTION_LIST_RESET,
  QUESTION_DELETE_REQUEST,
  QUESTION_DELETE_SUCCESS,
  QUESTION_DELETE_FAIL,
  QUESTION_UPDATE_REQUEST,
  QUESTION_UPDATE_SUCCESS,
  QUESTION_UPDATE_FAIL,
  QUESTION_UPDATE_RESET,
  QUESTION_DETAILS_REQUEST,
  QUESTION_DETAILS_SUCCESS,
  QUESTION_DETAILS_FAIL,
  QUESTION_DETAILS_RESET,
  QUESTION_1_DETAILS_REQUEST,
  QUESTION_1_DETAILS_SUCCESS,
  QUESTION_1_DETAILS_FAIL,
  QUESTION_1_DETAILS_RESET,
  QUESTION_SOLVE_REQUEST,
  QUESTION_SOLVE_SUCCESS,
  QUESTION_SOLVE_FAIL,
  QUESTION_SOLVE_RESET,
  QUESTION_PRACTICE_REQUEST,
  QUESTION_PRACTICE_SUCCESS,
  QUESTION_PRACTICE_FAIL,
  QUESTION_PRACTICE_RESET,
} from '../constants/questionBankConstants'

export const createQuestionReducer = (state = {}, action) => {
  switch (action.type) {
    case QUESTION_CREATE_REQUEST:
      return { loading: true }
    case QUESTION_CREATE_SUCCESS:
      return { loading: false, success: true, question: action.payload }
    case QUESTION_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case QUESTION_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const listQuestionsReducer = (state = {}, action) => {
  switch (action.type) {
    case QUESTION_LIST_REQUEST:
      return { loading: true }
    case QUESTION_LIST_SUCCESS:
      return { loading: false, success: true, questions: action.payload }
    case QUESTION_LIST_FAIL:
      return { loading: false, error: action.payload }
    case QUESTION_LIST_RESET:
      return {}
    default:
      return state
  }
}

export const deleteQuestionReducer = (state = {}, action) => {
  switch (action.type) {
    case QUESTION_DELETE_REQUEST:
      return { loading: true }
    case QUESTION_DELETE_SUCCESS:
      return { loading: false, success: true }
    case QUESTION_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updateQuestionReducer = (state = {}, action) => {
  switch (action.type) {
    case QUESTION_UPDATE_REQUEST:
      return { loading: true, ...state }
    case QUESTION_UPDATE_SUCCESS:
      return { loading: false, success: true, question: action.payload }
    case QUESTION_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case QUESTION_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const questionDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case QUESTION_DETAILS_REQUEST:
      return { loading: true }
    case QUESTION_DETAILS_SUCCESS:
      return { loading: false, success: true, question: action.payload }
    case QUESTION_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    case QUESTION_DETAILS_RESET:
      return {}
    default:
      return state
  }
}

export const practiceQuestionDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case QUESTION_1_DETAILS_REQUEST:
      return { loading: true }
    case QUESTION_1_DETAILS_SUCCESS:
      return { loading: false, success: true, question: action.payload }
    case QUESTION_1_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    case QUESTION_1_DETAILS_RESET:
      return {}
    default:
      return state
  }
}

export const solvePracticeQuestionReducer = (state = {}, action) => {
  switch (action.type) {
    case QUESTION_SOLVE_REQUEST:
      return { loading: true }
    case QUESTION_SOLVE_SUCCESS:
      return { loading: false, success: true, status: action.payload }
    case QUESTION_SOLVE_FAIL:
      return { loading: false, error: action.payload }
    case QUESTION_SOLVE_RESET:
      return {}
    default:
      return state
  }
}

export const getPracticeQuestionsReducer = (state = {}, action) => {
  switch (action.type) {
    case QUESTION_PRACTICE_REQUEST:
      return { loading: true }
    case QUESTION_PRACTICE_SUCCESS:
      return { loading: false, success: true, questions: action.payload }
    case QUESTION_PRACTICE_FAIL:
      return { loading: false, error: action.payload }
    case QUESTION_PRACTICE_RESET:
      return {}
    default:
      return state
  }
}
