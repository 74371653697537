export const MAIL_FEEDBACK_REQUEST = 'MAIL_FEEDBACK_REQUEST'
export const MAIL_FEEDBACK_SUCCESS = 'MAIL_FEEDBACK_SUCCESS'
export const MAIL_FEEDBACK_FAIL = 'MAIL_FEEDBACK_FAIL'
export const MAIL_FEEDBACK_RESET = 'MAIL_FEEDBACK_RESET'

export const MAIL_CONTACT_REQUEST = 'MAIL_CONTACT_REQUEST'
export const MAIL_CONTACT_SUCCESS = 'MAIL_CONTACT_SUCCESS'
export const MAIL_CONTACT_FAIL = 'MAIL_CONTACT_FAIL'
export const MAIL_CONTACT_RESET = 'MAIL_CONTACT_RESET'

export const MAIL_RESET_REQUEST = 'MAIL_RESET_REQUEST'
export const MAIL_RESET_SUCCESS = 'MAIL_RESET_SUCCESS'
export const MAIL_RESET_FAIL = 'MAIL_RESET_FAIL'
export const MAIL_RESET_RESET = 'MAIL_RESET_RESET'

export const MAIL_VERIFICATION_REQUEST = 'MAIL_VERIFICATION_REQUEST'
export const MAIL_VERIFICATION_SUCCESS = 'MAIL_VERIFICATION_SUCCESS'
export const MAIL_VERIFICATION_FAIL = 'MAIL_VERIFICATION_FAIL'
