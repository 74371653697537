export const COMPILER_SUBMISSION_REQUEST = 'COMPILER_SUBMISSION_REQUEST'
export const COMPILER_SUBMISSION_SUCCESS = 'COMPILER_SUBMISSION_SUCCESS'
export const COMPILER_SUBMISSION_FAIL = 'COMPILER_SUBMISSION_FAIL'
export const COMPILER_SUBMISSION_RESET = 'COMPILER_SUBMISSION_RESET'

export const COMPILER_SUBMISSION_RESULTS_REQUEST =
  'COMPILER_SUBMISSION_RESULTS_REQUEST'
export const COMPILER_SUBMISSION_RESULTS_SUCCESS =
  'COMPILER_SUBMISSION_RESULTS_SUCCESS'
export const COMPILER_SUBMISSION_RESULTS_FAIL =
  'COMPILER_SUBMISSION_RESULTS_FAIL'
export const COMPILER_SUBMISSION_RESULTS_RESET =
  'COMPILER_SUBMISSION_RESULTS_RESET'

export const COMPILER_SUBMISSION_RESULTS_PROCESSING =
  'COMPILER_SUBMISSION_RESULTS_PROCESSING'

export const COMPILER_MULTI_SUBMISSION_RESULTS_REQUEST =
  'COMPILER_MULTI_SUBMISSION_RESULTS_REQUEST'
export const COMPILER_MULTI_SUBMISSION_RESULTS_SUCCESS =
  'COMPILER_MULTI_SUBMISSION_RESULTS_SUCCESS'
export const COMPILER_MULTI_SUBMISSION_RESULTS_FAIL =
  'COMPILER_MULTI_SUBMISSION_RESULTS_FAIL'
export const COMPILER_MULTI_SUBMISSION_RESULTS_RESET =
  'COMPILER_MULTI_SUBMISSION_RESULTS_RESET'

export const COMPILER_MULTI_SUBMISSION_RESULTS_PROCESSING =
  'COMPILER_MULTI_SUBMISSION_RESULTS_PROCESSING'

export const COMPILER_MULTI_SUBMISSION_REQUEST =
  'COMPILER_MULTI_SUBMISSION_REQUEST'
export const COMPILER_MULTI_SUBMISSION_SUCCESS =
  'COMPILER_MULTI_SUBMISSION_SUCCESS'
export const COMPILER_MULTI_SUBMISSION_FAIL = 'COMPILER_MULTI_SUBMISSION_FAIL'
export const COMPILER_MULTI_SUBMISSION_RESET = 'COMPILER_MULTI_SUBMISSION_RESET'
