export const LEAD_CREATE_REQUEST = 'LEAD_CREATE_REQUEST'
export const LEAD_CREATE_SUCCESS = 'LEAD_CREATE_SUCCESS'
export const LEAD_CREATE_FAIL = 'LEAD_CREATE_FAIL'
export const LEAD_CREATE_RESET = 'LEAD_CREATE_RESET'

export const LEAD_DETAILS_REQUEST = 'LEAD_DETAILS_REQUEST'
export const LEAD_DETAILS_SUCCESS = 'LEAD_DETAILS_SUCCESS'
export const LEAD_DETAILS_FAIL = 'LEAD_DETAILS_FAIL'
export const LEAD_DETAILS_RESET = 'LEAD_DETAILS_RESET'

export const LEAD_UPDATE_REQUEST = 'LEAD_UPDATE_REQUEST'
export const LEAD_UPDATE_SUCCESS = 'LEAD_UPDATE_SUCCESS'
export const LEAD_UPDATE_FAIL = 'LEAD_UPDATE_FAIL'
export const LEAD_UPDATE_RESET = 'LEAD_UPDATE_RESET'

export const LEAD_DELETE_REQUEST = 'LEAD_DELETE_REQUEST'
export const LEAD_DELETE_SUCCESS = 'LEAD_DELETE_SUCCESS'
export const LEAD_DELETE_FAIL = 'LEAD_DELETE_FAIL'
export const LEAD_DELETE_RESET = 'LEAD_DELETE_RESET'

export const LEAD_MY_DETAILS_REQUEST = 'LEAD_MY_DETAILS_REQUEST'
export const LEAD_MY_DETAILS_SUCCESS = 'LEAD_MY_DETAILS_SUCCESS'
export const LEAD_MY_DETAILS_FAIL = 'LEAD_MY_DETAILS_FAIL'

export const LEAD_MY_UPDATE_REQUEST = 'LEAD_MY_UPDATE_REQUEST'
export const LEAD_MY_UPDATE_SUCCESS = 'LEAD_MY_UPDATE_SUCCESS'
export const LEAD_MY_UPDATE_FAIL = 'LEAD_MY_UPDATE_FAIL'
export const LEAD_MY_UPDATE_RESET = 'LEAD_MY_UPDATE_RESET'

export const LEAD_MY_ADD_REQUEST = 'LEAD_MY_ADD_REQUEST'
export const LEAD_MY_ADD_SUCCESS = 'LEAD_MY_ADD_SUCCESS'
export const LEAD_MY_ADD_FAIL = 'LEAD_MY_ADD_FAIL'
export const LEAD_MY_ADD_RESET = 'LEAD_MY_ADD_RESET'

export const LEAD_INACTIVE_REQUEST = 'LEAD_INACTIVE_REQUEST'
export const LEAD_INACTIVE_SUCCESS = 'LEAD_INACTIVE_SUCCESS'
export const LEAD_INACTIVE_FAIL = 'LEAD_INACTIVE_FAIL'
