import {
  COUPON_CREATE_FAIL,
  COUPON_CREATE_SUCCESS,
  COUPON_CREATE_REQUEST,
  COUPON_CREATE_RESET,
  COUPON_DETAILS_REQUEST,
  COUPON_DETAILS_SUCCESS,
  COUPON_DETAILS_FAIL,
  COUPON_DETAILS_ONE_REQUEST,
  COUPON_DETAILS_ONE_SUCCESS,
  COUPON_DETAILS_ONE_FAIL,
  COUPON_DELETE_REQUEST,
  COUPON_DELETE_SUCCESS,
  COUPON_DELETE_FAIL,
  COUPON_UPDATE_REQUEST,
  COUPON_UPDATE_SUCCESS,
  COUPON_UPDATE_FAIL,
  COUPON_UPDATE_RESET,
  COUPON_APPLY_REQUEST,
  COUPON_APPLY_SUCCESS,
  COUPON_APPLY_FAIL,
  COUPON_APPLY_RESET,
} from '../constants/couponConstants'

export const couponCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case COUPON_CREATE_REQUEST:
      return { loading: true }
    case COUPON_CREATE_SUCCESS:
      return { loading: false, success: true, coupon: action.payload }
    case COUPON_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case COUPON_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const couponListReducer = (state = {}, action) => {
  switch (action.type) {
    case COUPON_DETAILS_REQUEST:
      return { loading: true }
    case COUPON_DETAILS_SUCCESS:
      return { loading: false, success: true, coupons: action.payload }
    case COUPON_DETAILS_FAIL:
      return { loading: false, error: action.payload }

    default:
      return state
  }
}

export const couponDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case COUPON_DETAILS_ONE_REQUEST:
      return { loading: true }
    case COUPON_DETAILS_ONE_SUCCESS:
      return { loading: false, success: true, coupon: action.payload }
    case COUPON_DETAILS_ONE_FAIL:
      return { loading: false, error: action.payload }

    default:
      return state
  }
}

export const couponDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case COUPON_DELETE_REQUEST:
      return { loading: true }
    case COUPON_DELETE_SUCCESS:
      return { loading: false, success: true }
    case COUPON_DELETE_FAIL:
      return { loading: false, error: action.payload }

    default:
      return state
  }
}

export const updateCouponReducer = (state = { coupon: {} }, action) => {
  switch (action.type) {
    case COUPON_UPDATE_REQUEST:
      return { loading: true, ...state }
    case COUPON_UPDATE_SUCCESS:
      return { loading: false, success: true, coupon: action.payload }
    case COUPON_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case COUPON_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const applyCouponReducer = (state = { coupon: {} }, action) => {
  switch (action.type) {
    case COUPON_APPLY_REQUEST:
      return { loading: true, ...state }
    case COUPON_APPLY_SUCCESS:
      return { loading: false, success: true, toBePaid: action.payload }
    case COUPON_APPLY_FAIL:
      return { loading: false, error: action.payload }
    case COUPON_APPLY_RESET:
      return {}
    default:
      return state
  }
}
