import {
  LEAD_CREATE_FAIL,
  LEAD_CREATE_REQUEST,
  LEAD_CREATE_SUCCESS,
  LEAD_CREATE_RESET,
  LEAD_DETAILS_FAIL,
  LEAD_DETAILS_REQUEST,
  LEAD_DETAILS_SUCCESS,
  LEAD_DETAILS_RESET,
  LEAD_UPDATE_FAIL,
  LEAD_UPDATE_REQUEST,
  LEAD_UPDATE_SUCCESS,
  LEAD_UPDATE_RESET,
  LEAD_DELETE_FAIL,
  LEAD_DELETE_REQUEST,
  LEAD_DELETE_SUCCESS,
  LEAD_DELETE_RESET,
  LEAD_MY_ADD_FAIL,
  LEAD_MY_ADD_REQUEST,
  LEAD_MY_ADD_SUCCESS,
  LEAD_MY_ADD_RESET,
  LEAD_INACTIVE_FAIL,
  LEAD_INACTIVE_REQUEST,
  LEAD_INACTIVE_SUCCESS,
  LEAD_MY_DETAILS_FAIL,
  LEAD_MY_DETAILS_REQUEST,
  LEAD_MY_DETAILS_SUCCESS,
  LEAD_MY_UPDATE_FAIL,
  LEAD_MY_UPDATE_REQUEST,
  LEAD_MY_UPDATE_SUCCESS,
  LEAD_MY_UPDATE_RESET,
} from '../constants/leadsConstants'

export const leadCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_CREATE_REQUEST:
      return { loading: true }
    case LEAD_CREATE_SUCCESS:
      return { loading: false, success: true }
    case LEAD_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case LEAD_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const leadsDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_DETAILS_REQUEST:
      return { loading: true }
    case LEAD_DETAILS_SUCCESS:
      return { loading: false, success: true, leads: action.payload }
    case LEAD_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    case LEAD_DETAILS_RESET:
      return {}
    default:
      return state
  }
}

export const leadUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_UPDATE_REQUEST:
      return { loading: true }
    case LEAD_UPDATE_SUCCESS:
      return { loading: false, success: true }
    case LEAD_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case LEAD_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const leadDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_DELETE_REQUEST:
      return { loading: true }
    case LEAD_DELETE_SUCCESS:
      return { loading: false, success: true }
    case LEAD_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case LEAD_DELETE_RESET:
      return {}
    default:
      return state
  }
}

export const addMyLeadReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_MY_ADD_REQUEST:
      return { loading: true }
    case LEAD_MY_ADD_SUCCESS:
      return { loading: false, success: true, data: action.payload }
    case LEAD_MY_ADD_FAIL:
      return { loading: false, error: action.payload }
    case LEAD_MY_ADD_RESET:
      return {}
    default:
      return state
  }
}

export const inactiveLeadsReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_INACTIVE_REQUEST:
      return { loading: true }
    case LEAD_INACTIVE_SUCCESS:
      return { loading: false, success: true, data: action.payload }
    case LEAD_INACTIVE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const myLeadsReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_MY_DETAILS_REQUEST:
      return { loading: true }
    case LEAD_MY_DETAILS_SUCCESS:
      return { loading: false, success: true, data: action.payload }
    case LEAD_MY_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const editLeadReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_MY_UPDATE_REQUEST:
      return { loading: true }
    case LEAD_MY_UPDATE_SUCCESS:
      return { loading: false, success: true, data: action.payload }
    case LEAD_MY_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case LEAD_MY_UPDATE_RESET:
      return {}
    default:
      return state
  }
}
